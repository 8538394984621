import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import PieChart from './PieChart';
import ProjectHierarchyChart from './ProjectHierarchyChart';

const HomeDashboard = ({ homeDashboardData }) => {
    const countInfo = [
        { id: 1, label: 'IOT Sensors', count: homeDashboardData?.sensor_count },
        { id: 2, label: 'Projects', count: homeDashboardData?.projects_count },
        { id: 3, label: 'Buildings', count: homeDashboardData?.facilities_count }
    ]

    const formatNumber = (num) => {
        if (num >= 1000) {
          return (num / 1000).toFixed(2) + 'k';
        }
        return num;
    }

    return (
        <div className='w3-margin-top'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {countInfo?.map((item) => (
                                    <Grid key={item.label} item md={4} xs={12}>
                                        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                            <CardContent style={{ flexGrow: 1, padding: 24 }}>
                                                <div className='home-dashboard-title'>
                                                    {item.label}
                                                </div>
                                                <div className='home-dashboard-count'>
                                                    {formatNumber(item.count)}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Card style={{ padding: 20 }}>
                                <div className='home-dashboard-title'>
                                    IOT Sensor Parameters
                                </div>
                                <PieChart
                                    chartData={homeDashboardData?.sensor_parameters}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card style={{ padding: 20 }}>
                        <div className='home-dashboard-title'>
                            Project Hierarchy
                        </div>
                        <ProjectHierarchyChart
                            hierarchyData={homeDashboardData?.project_hierarchy_data}
                        />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default HomeDashboard;
