import  React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField'; 
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { postRegister, acceptInvite, getInviteByToken } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import { Link, useNavigate, useParams } from "react-router-dom";
import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo.svg'
import {  CircularProgress } from '@mui/material';
import LandingPage from '../Common/LandingPage';





const defaultTheme = createTheme();

export default function SignUp({invite=false}) {
  const { token } = useParams();

  const dispatch = useDispatch();
    const initForm = {
        uniqueId: '',
        password: '',
        confirm: ''
    };

    let navigate = useNavigate();
    const initErr = {};
    const [form, setForm] = useState(initForm);
    const [errors, setErrors] = useState(initErr);
    const [alertMessage, setAlertMessage] = useState();
    const [passReg, setPassReg] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    useEffect(() => {
      dispatch(getInviteByToken(token)).then((res) => {
          const response = res?.data;
          if (!response) {
              return
          }
          const flowData = {
            name: response.name,
            uniqueId: response.emailId
          }
          setForm(flowData);
      })
  }, [token])

    const handleChange = (e) => {
      const { value, name } = e.target;
      const fieldValue = Object.assign({}, form);
      const errorField = Object.assign({}, errors);
      if (errorField[name]) {
          errorField[name] = null;
          setErrors(errorField);
      }
      fieldValue[name] = value;
      if (name === 'uniqueId') {
          fieldValue[name] = value.toLowerCase();
      }
      setPassReg(false);
      setForm(fieldValue);
  };

  const emailValidator = (email) =>{
    const pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i);
    return pattern.test(email);
  }

  const passwordValidator = (password)=>{
    const pattern = new RegExp(/^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*]).{8,20}$/);
    return pattern.test(password);
}

  const validateData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);

    if (!form.name) {
      hasError = true;
      err.name = 'This field is mandatory.';
  }
    if (!emailValidator(form.uniqueId)) {
        hasError = true;
        err.uniqueId = 'Enter a valid email.';
    }
    if (form.password !== form.confirm) {
        hasError = true;
        err.confirm = 'New Password and confirm password must be same.';
    }

    if (!passwordValidator(form.password)) {
        hasError = true;
        setPassReg(true);
        err.password = `Password doesn't meet the requirements`;
    }

    Object.keys(form).forEach((key) => {
        if (typeof (form[key]) === 'string' && key !== 'password' && key !== 'confirm') {
            if (!form[key].match(/\w/)) {
                hasError = true;
                err[key] = 'This field is mandatory';
            }
        }
        if (!form[key]) {
            hasError = true;
            err[key] = 'This field is mandatory';
        }
    });
    if (hasError) {
        setErrors(err);
        return false;
    }
    return form;
};
if(token && !form.name) {
  return <Grid container justify="center" alignItems="center"><CircularProgress /></Grid>

}

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateData();
    if (valid) {
      if(token && invite) {
        valid.token = token
        dispatch(acceptInvite(valid)).then((resp) => {
          const res = resp && resp.data;
            if (res && res.success) {
                setAlertMessage('Registered successfully...!');
            } else if (res && !res.success) {
                setAlertMessage('User Already Exists, Please Login...!');
            } else if (res && res.data) {
                setErrors(res.data);
            } else {
                setAlertMessage('Something went wrong. Try again...!');
            }
        })
      } else {
        dispatch(postRegister(valid)).then((resp) => {
            const res = resp && resp.data;
            if (res && res.success) {
                setAlertMessage('Registered successfully...!');
            } else if (res && !res.success) {
                setAlertMessage('User Already Exists, Please Login...!');
            } else if (res && res.data) {
                setErrors(res.data);
            } else {
                setAlertMessage('Something went wrong. Try again...!');
            }
        });
      }
    }
};

  return (
   <LandingPage>
      <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" style={{ position:'relative' }}>
        <CssBaseline />
        <Box
        padding={2}
        className="landing-form"
        >
          <img src={GreenvironmentLogo} style={{height:"120px"}}/>
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          {/* <Typography component="h4" variant="h5">
            Sign up
          </Typography> */}
          <Typography component="h6" variant="h6" style={{ marginTop: 18, fontWeight: 'bold' }}>
              SUSTAINABILITY DASHBOARD
          </Typography>
          <Typography style={{ marginTop: 20 }}>
            Sign up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }} style={{width: '-webkit-fill-available'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <input
                    type="text"
                    name="name"
                    placeholder="Name" 
                    value={form.name}
                    disabled={token}
                    onChange={handleChange}
                    className={`custom-input-style ${token ? 'w3-disabled' : ''}`}
                  />
                  {errors.name && <div className='error-text '>{errors.name}</div>}
              </Grid>
              <Grid item xs={12}>
                <input
                  name="uniqueId"
                  label="Email"
                  disabled={token}
                  value={form.uniqueId}
                  onChange={handleChange}
                  className={`custom-input-style ${token ? 'w3-disabled' : ''}`}
                />
                  {errors.uniqueId && <div className='error-text '>{errors.uniqueId}</div>}
              </Grid>
              <Grid item xs={12} className='position-relative'>
                <input
                    name="password"
                    placeholder="Password*"
                    value={form.password}
                    onChange={handleChange}
                    errors={errors.password}
                    className='custom-input-style'
                    type={showPassword ? 'text' : 'password'} // toggle password visibility
                />
                 <IconButton size="small" className='password-eye-icon' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                {errors.password && <div className='error-text '>{errors.password}</div>}
              </Grid>
                {
                  passReg === true &&
                  <div>
                      <Typography className="w3-text-red w3-padding">
                            <li> Minimum password length 8</li>
                            <li> Maximum password length 20</li>
                            <li>Required at least one digit</li>
                            <li>Required at least one upper case</li>
                            <li> Required at least one lower case</li>
                            <li>Required at least one symbol</li>
                      </Typography>
                  </div>
                }
              <Grid item xs={12} className='position-relative'>
                <input
                    name="confirm"
                    placeholder="Confirm Password*"
                    value={form.confirm}
                    onChange={handleChange}
                    errors={errors.confirm}
                    className='custom-input-style'
                    type={showPasswordConfirm ? 'text' : 'password'} // toggle password visibility
                />
                 <IconButton size="small" className='password-eye-icon' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                    {showPasswordConfirm ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                {errors.confirm && <div className='error-text '>{errors.confirm}</div>}
              </Grid>
            </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="signup-btn"
                onClick={(e) => handleSubmit(e)}
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {alertMessage
      ? (
          <AlertDialog
              handleClose={() => {
                  setAlertMessage(null);
                  navigate('/login');
              }}
              message={alertMessage}
          />
      ) : null}
      </Container>
      
    </ThemeProvider>
   </LandingPage>
  );
}