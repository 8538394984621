import * as React from 'react';
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField'; 
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo.svg'
import { postForgotPassword } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import Loader from '../Common/Loader';
import LandingPage from '../Common/LandingPage';


const defaultTheme = createTheme();

const ForgotPassword = () => {

  

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const initForm = {
      email: '',
  };
  const initErr = {};
  const [form, setForm] = useState(initForm);
  const [errors, setErrors] = useState(initErr);
  const [alertMessage, setAlertMessage] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const fieldValue = Object.assign({}, form);
    const errorField = Object.assign({}, errors);
    if (errorField[name]) {
        errorField[name] = null;
        setErrors(errorField);
    }
    fieldValue[name] = value;
    setForm(fieldValue);
};

const emailValidator = (email) =>{
  const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i);
  return pattern.test(email);
}

const validateData = () => {
  let hasError = false;
  const err = Object.assign({}, errors);
  if (!emailValidator(form.email)) {
      hasError = true;
      err.email = 'Enter a valid email.';
  }
  Object.keys(form).forEach((key) => {
      if (typeof (form[key]) === 'string' && key !== 'password' && key !== 'confirm') {
          if (!form[key].match(/\w/)) {
              hasError = true;
              err[key] = 'This field is mandatory';
          }
      }
      if (!form[key]) {
          hasError = true;
          err[key] = 'This field is mandatory';
      }
  });
  if (hasError) {
      setErrors(err);
      return false;
  }
  return form;
};

const handleSubmit = (e) => {
  e.preventDefault();
   const valid = validateData();
  if (valid) {
      setShowLoader(true)
      setDisableBtn(true);
      dispatch(postForgotPassword(valid)).then((resp) => {
          setShowLoader(false)
          const res = resp && resp.data;
          
          if (res && res.success) {
              setAlertMessage(res.message);
          } else if (res && res.data) {
              setErrors(res.data);
          } else {
              setAlertMessage('Something went wrong. Try again...!');
          }
          setDisableBtn(false);
      });
  }
};

  return (
    <LandingPage>
      <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" style={{ position:'relative' }}>
        <CssBaseline />
        <Box
        className="landing-form"
        padding={2}
        >
          <img src={GreenvironmentLogo} style={{marginTop: '50px',height:"120px"}} />
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h6" variant="h6" style={{ marginTop: 18, fontWeight: 'bold' }}>
              SUSTAINABILITY DASHBOARD
          </Typography>
          <Typography style={{ marginTop: 20 }}>
            Forgot Password
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }} style={{width: '-webkit-fill-available'}}>
            <Grid container spacing={2}>
              
              <Grid item xs={12}>
                <input
                  className='custom-input-style'
                  placeholder='Email*'
                  name="email"
                  required
                  value={form.email.toLowerCase()}
                  onChange={handleChange}
                />
              {errors.email && <div className='error-text '>{errors.email}</div>}
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Send Reset Link
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                Back to Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {alertMessage
                ? (
                    <AlertDialog
                        handleClose={() => {
                            setAlertMessage(null);
                            navigate(-1);
                        }}
                        message={alertMessage}
                    />
                ) : null}
            {showLoader && <Loader open={showLoader}/>}
      </Container>
    </ThemeProvider>
    </LandingPage>
  );
}

export default ForgotPassword