import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
//import { postSocialLogin } from '../../Redux/actions';
import { Button, Grid, Typography } from '@mui/material';
import Link from '@mui/material/Link';
//import { getCookie } from '../../utils';

// declare global {
//     interface Window {
//         FB;
//         gapi;
//         fbAsyncInit;
//         gapiAsyncInit;
//         google;
//     }
// }

function SocialLogin(props) {

    const dispatch = useDispatch();
    const ssoLoginAllowedDomains = ['g2.d2.ceegees.in', 'gaws.d2.ceegees.in', 'amazon.greenvironment.co.in'];
    const showSsoLogin = ssoLoginAllowedDomains?.some(domain => domain === window?.location?.host);
    if (!showSsoLogin) {
        return null;
    }
    return (
        <div>
            <Grid container spacing={2} columns={{ xs: 1, sm: 14, md: 15 }} className="w3-row w3-margin-bottom w3-padding-top-24" style={{justifyContent:'center'}} >
                <Grid item xs={8} sm={16} md={16}>
                    <Typography align='center'>
                        <Link href="/api/v1/auth/sso-login">Login Using SSO</Link>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default SocialLogin
