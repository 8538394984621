import React from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { Delete } from '@mui/icons-material';

export const DeleteIcon = () => {
    return <Delete className="cursor-pointer"
        style={{ color: '#d24949' }}
    />
}
function TableUI(props) {
    const { stickyHeader, classes, tableContent, tableColumns, scrollable, borderTop } = props
    return (

        <TableContainer className={scrollable ? classes.emTable : ''} component={Paper} elevation={0}>
            <Table className={borderTop ? `w3-border-top` : null} stickyHeader={stickyHeader} >
                <TableHead>
                    <TableRow >
                        {tableColumns.map((cols, idx) => {
                            return (
                                <TableCell style={{ backgroundColor: 'white', fontWeight: 'bold' }}
                                    align='left'>{cols}</TableCell>
                            )
                        })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableContent}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableUI