import { Button } from '@mui/material';
import React, { useState } from 'react';
import Plot from 'react-plotly.js';
import InsightsModal from '../../Common/InsightsModal';
import { Lightbulb } from '@mui/icons-material';
import { formatDate, generateInsightKey } from '../../../utils';

export default function WaterConsumptionChart({ tableData, client_id, from_date, to_date, media_id }) {

    const [openModal, setOpenModal] = useState(false);
    const [showInsightButton, setShowInsightButton] = useState(true)
    const [insightKey, setInsightKey] = useState(null);

    const convertedJson = [];
    tableData?.forEach((obj, index) => {
        convertedJson.push(obj);
    });

    const prepareInsightModal = () => {
        //Converiting the date format to YYYY-MM-DD
        const formattedFromDate = formatDate(new Date(from_date));
        const formattedToDate = formatDate(new Date(to_date));

        const key = generateInsightKey(client_id, formattedFromDate, formattedToDate, media_id, 'water-consumption')
        setInsightKey(key);
        setOpenModal(true);
        setShowInsightButton(false)
    }

    if (!tableData || tableData?.length === 0) {
        return <div><div style={{ fontSize: 16, margin: '28px 0 28px 0' }}>No Data Found</div>
        </div>
    }

    const hierarchy = {
        children: []
    };
    const categoryMap = {};
    const categoryValues = {};
    let hasFreshWater = false;
    let hasRecycledWater = false;

    tableData.forEach(item => {
        if (!categoryMap[item.category]) {
            categoryMap[item.category] = {
                name: item.category,
                children: []
            };
            hierarchy.children.push(categoryMap[item.category]);
            categoryValues[item.category] = 0;
        }
        categoryMap[item.category].children.push({
            name: item.group_name,
            value: item.value
        });
        categoryValues[item.category] += item.value;
        if (item.category === "Fresh Water") {
            hasFreshWater = true;
        }
        if (item.category === "Recycled Water") {
            hasRecycledWater = true;
        }
    });

    const dataList = [];
    hierarchy.children.forEach(item => {
        dataList.push({
            label: item.name,
            parent: "",
            value: categoryValues[item.name]
        });

        item.children.forEach(child => {
            dataList.push({
                label: child.name,
                parent: item.name,
                value: child.value
            });
        });
    });

    if (!hasFreshWater) {
        dataList.push({
            label: "Fresh Water",
            parent: "",
            value: 0
        });
    }
    if (!hasRecycledWater) {
        dataList.push({
            label: "Recycled Water",
            parent: "",
            value: 0
        });
    }
    const allValuesZero = dataList?.every(item => item.value === 0);

    const getGraphColor = (label, parent) => {
        const freshRecycledColorMap = {
            "Fresh Water": "#4682B4",
            "Recycled Water": "#53BE04",
        };
        return parent ? freshRecycledColorMap[parent] : freshRecycledColorMap[label]
    };

    const data = [{
        type: "sunburst",
        labels: dataList?.map(item => item.label),
        parents: dataList?.map(item => item.parent),
        values: dataList?.map(item => item.value),
        outsidetextfont: { size: 20, color: "#377eb8" },
        leaf: { opacity: 0.4 },
        branchvalues: "total",
        marker: {
            line: { width: 2 },
            colors: dataList.map(item => getGraphColor(item.label, item.parent))
        },
    }];

    const layout = {
        margin: { l: 0, r: 0, b: 0, t: 0 },
    };

    if (allValuesZero) {
        layout.annotations = [
            {
                text: "No data to display",
                showarrow: false,
                xref: 'paper',
                yref: 'paper',
                x: 0.5,
                y: 0.5,
                font: {
                    size: 16,
                    color: '#757575'
                }
            }
        ];
    }

    return (
        <div >
            <div style={{ textAlign: 'right' }}>
                {tableData && showInsightButton && !allValuesZero && (
                    <Button
                        id="water-consumption-insight"
                        size='small'
                        startIcon={<Lightbulb color='info' />}
                        variant='outlined'
                        onClick={prepareInsightModal}>
                        View Insights
                    </Button>
                )}
            </div>
            <Plot
                style={{
                    margin: "0 auto",
                    height: '100%',
                    width: '90%',
                }}

                data={data}
                layout={layout}
            />

            <InsightsModal
                convertedJson={convertedJson}
                openInsight={openModal}
                closeInsight={() => setOpenModal(false)}
                setShowInsightButton={() => setShowInsightButton(true)}
                insightKey={insightKey}
            />
        </div>
    );
}