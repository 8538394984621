
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { deleteOnlineMonitoring, downloadOnlineMtrngList, downloadOnlnMtrngData, getMeterById, getOnlineMonitoringDetails, getOnlineMonitoringList, setStoreData, updateOnlineMonitoringDetails } from '../../Redux/actions';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { DeleteIcon } from '../Common/TableUI';
import { Edit } from '@mui/icons-material';
import ConfirmDialog from '../Common/ConfirmDialog';
import { Can } from '../../Context/Can';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { updateParams } from '../../utils';
import {  useSearchParams,  useNavigate ,useLocation } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Loader from '../Common/Loader';

export default function OnlineMonitoring() {

    const { id: id, client_id: client_id } = useParams();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [showLoader, setShowLoader] = useState(false)
    const [onlineMonitoringData, setOnlineMonitoringData] = useState([])
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [handleItemId, setHandleItemId] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [onlineMonitoringValue, setOnlineMonitoringValue] = useState('');
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [disableNextBtn, setDisableNextBtn] = useState(false);
    const [fromDateAndTime, setFromDateAndTime] = useState('');
    const [toDateAndTime, setToDateAndTime] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(null);
    const [meterName, setMeterName] = useState(null);
    const [disableClrFltrBtn , setDisableClrFltrBtn] = useState(true)
    const [disableDownloadBtn , setDisableDownloadBtn] = useState(true)
    const [downloadLoader, setDownloadLoader] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (fromDateAndTime && toDateAndTime && queryParams.get('page')) {
            fetchData();
        }
        if (existingParams?.page) {
            return
        }
        updateQueryParams({ page: 1 })
    }, [queryParams])

    useEffect(() => {
        if (fromDateAndTime === '' && toDateAndTime === '' && queryParams.get('page')) {
            fetchData();
        }
    }, [fromDateAndTime, toDateAndTime, queryParams]);

    useEffect(() => {
        dispatch(getMeterById(client_id, id)).then(resp => {
            const meterData = resp?.data?.meterData
            if (!meterData) {
                return
            }
            setMeterName(meterData?.metername)
        })
    },[])

    //Fetching the all OnlineMonitoring Data based on pagination and filters, if applied.
    const fetchData = () => {
        setOnlineMonitoringData([]);
        setShowLoader(true);
        const form = {
            ...existingParams,
            page: existingParams?.page,
            fromDate: fromDateAndTime ? fromDateAndTime?.format('YYYY-MM-DD') : '',
            toDate: toDateAndTime ? toDateAndTime?.format('YYYY-MM-DD') : '',
            fromTime: fromDateAndTime ? fromDateAndTime?.format('HH:mm:ss') : '',
            toTime: toDateAndTime ? toDateAndTime?.format('HH:mm:ss') : '',
            client_id
        }
        dispatch(getOnlineMonitoringList(id, form)).then((res) => {
            setDisableNextBtn(false);
            setShowLoader(false);
            setOnlineMonitoringData(res?.data?.onlineMonitoringData)
            setItemsPerPage(res?.data?.itemsPerPage)
            if(!(res?.data?.onlineMonitoringData?.length > 0)){
                setDisableNextBtn(true)
            } else if ( fromDateAndTime &&  toDateAndTime ){
                setDisableDownloadBtn(false)
            }
        })
    }

    //Handle edit online monitoring value starts here
    const handleOpenEditDialog = (itemId) => {
        setOpenEditDialog(true);
        setHandleItemId(itemId);
        dispatch(getOnlineMonitoringDetails(itemId)).then((res) => {
            setOnlineMonitoringValue(res?.data?.onlineMonitoringValue?.value)
        })
    }

    const handleCloseEditDialog = () => {
        setUpdateBtnDisabled(true);
        setOpenEditDialog(false);
        setOnlineMonitoringValue('')
        setHandleItemId('');
        setErrors({})
    };

    const validateData = () => {
        const err = {...errors}
        let hasErrors = false;

        if(onlineMonitoringValue === '' || onlineMonitoringValue.trim() === '') {
            hasErrors = true
            err.onlineMonitoringValue = 'This field is mandatory'
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    }

    const handlUpdateOnlinMonitoringVal = () => {
        setUpdateBtnDisabled(true);
        const checkForm = validateData();
        if(checkForm){
            setOpenEditDialog(false);
            const data = { 
                value: onlineMonitoringValue,
                client_id
            }
            const itemId = handleItemId
            dispatch(updateOnlineMonitoringDetails(data, itemId)).then((res) => {
                updateResponse(res, res?.data?.message);
                fetchData();
            })
        }
    };

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))
    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
    }

    const handleValueChange = (key) => {
        setUpdateBtnDisabled(false);
        const err = Object.assign({}, errors);
        if(err[key]) {
            err[key] = '';
            setErrors(err);
        }
    } //Handle edit online monitoring value ends here

    //Handle delete online monitoring data
    const triggerDelete = (item) => {
        setShowConfirmDialog(true);
        setHandleItemId(item);
    }

    const handleDeleteOnlineMonitoring = () => {
        setShowConfirmDialog(false);
        dispatch(deleteOnlineMonitoring(client_id,handleItemId)).then((res) => {
            updateResponse(res, res?.data?.message);
            fetchData();
        })
    }

    //Handle Filters
    const handleApplyFilter = () => {
        setDisableDownloadBtn(true)
        if(fromDateAndTime === '' || toDateAndTime === ''){
            return
        }
        updateQueryParams({ page: 1 })
        if (queryParams.get('page') === '1') {
            fetchData();
        }
    }

    const handleClearFilter = () => {
        setDisableClrFltrBtn(true)
        setFromDateAndTime('')
        setToDateAndTime('')
        updateQueryParams({ page: 1 })
        setDisableDownloadBtn(true)
    }

    const handleDownload = () => {
        setDownloadLoader(true); 
        const form = {
            fromDate: fromDateAndTime ? fromDateAndTime?.format('YYYY-MM-DD') : '',
            toDate: toDateAndTime ? toDateAndTime?.format('YYYY-MM-DD') : '',
            fromTime: fromDateAndTime ? fromDateAndTime?.format('HH:mm:ss') : '',
            toTime: toDateAndTime ? toDateAndTime?.format('HH:mm:ss') : '',
            client_id
        }
        dispatch(downloadOnlineMtrngList(id,form)).then((res) => {
            const csvContent = res?.data?.csv
            if (csvContent) {
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    
                const link = document.createElement('a');
    
                const url = URL.createObjectURL(blob);
                link.href = url;
    
                link.setAttribute('download', `om_data_mid_${id}_${res?.data?.meteridFlg}_from_${form.fromDate}_${form.fromTime}_to_${form.toDate}_${form.toTime}.csv`);
    
                document.body.appendChild(link);
    
                link.click();
    
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
            }
            setDownloadLoader(false);
        })
    }

    //Handle pagination starts here
    const updateQueryParams = (params) => {
        const searchParams = updateParams(existingParams, params)
        navigate({ search: searchParams.toString() }, { replace: true });
    }

    const handleForwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const nextPage = currentPage + 1;
        updateQueryParams({ page: nextPage });
    };

    const handleBackwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const previousPage = currentPage > 1 ? currentPage - 1 : 1;
        updateQueryParams({ page: previousPage });
    }; 
    //Handle pagination ends here

    let onlineMonitoringDataList; 
    
    if (onlineMonitoringData?.length > 0) {
        onlineMonitoringDataList = (
            onlineMonitoringData.map((item) => {
                return <TableRow key={item?.id}
                onMouseEnter={() => {
                    setHoveredRowId(item?.id);
                }}
                onMouseLeave={() => {
                    setHoveredRowId(null);
                }}>
                    <TableCell>{item?.meterid_flg}</TableCell>
                    <TableCell style={{ maxWidth: '150px', wordWrap: 'break-word' }}>{item?.value}</TableCell>
                    <TableCell>{moment(item?.mdate).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{item?.mtime}</TableCell>
                    <TableCell>
                    <Box component="div" display="flex" justifyContent="right" >
                        <Can do='manage' on='EditOnlineMonitoring'>
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0 ,paddingLeft:'0px'}}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenEditDialog(item?.id)
                                    }}
                                    >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Can>
                        <Can do='manage' on='DeleteOnlineMonitoring'>
                            <Tooltip title="Delete">
                                <IconButton aria-label="delete" 
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        triggerDelete(item?.id)
                                    }}
                                >
                                    <DeleteIcon/> 

                                </IconButton>
                            </Tooltip>
                        </Can>
                    </Box>
                </TableCell>
                </TableRow>
            })
        )
    }

    return  <Grid>
                <Grid container>
                    <Grid item style={{ paddingTop: '10px' }}>
                    <h5 style={{ color: '#012970'}}>Online Monitoring {meterName && `- ${meterName}`}</h5>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: '26px 0px 16px 0px', justifyContent: 'flex-start'}}>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                            label="From Date and Time"
                            value={fromDateAndTime}
                            onChange={(fromDateTime) => {
                                setFromDateAndTime(fromDateTime);
                                setDisableClrFltrBtn(false)
                            }}
                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                            maxDateTime={dayjs(toDateAndTime)}
                            slotProps={{ textField: { error: false, size: 'small' } }}
                            disableFuture
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                            defaultValue={dayjs(new Date())}
                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                            label="To Date and Time"
                            value={toDateAndTime}
                            onChange={(toDateTime) => {
                                setToDateAndTime(toDateTime);
                                setDisableClrFltrBtn(false)
                            }}
                            minDateTime={dayjs(fromDateAndTime)}
                            slotProps={{ textField: { error: false, size: 'small' } }}
                            disableFuture
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <Button disabled={ (fromDateAndTime && toDateAndTime) ? false : true } variant="contained" color="primary" onClick={handleApplyFilter} style={{ height: '100%' }}>
                        Apply Filter
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={disableClrFltrBtn} variant="outlined" color="primary" onClick={handleClearFilter} style={{ height: '100%' }}>
                        Clear Filter
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={disableDownloadBtn} variant="outlined" color="primary" onClick={handleDownload} style={{ height: '100%' }}>
                        Download as csv
                        </Button>
                    </Grid>
                </Grid>
                <Grid paddingX={0} paddingY={1}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="simple table" style={{ tableLayout:  'auto' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', verticalAlign: 'top' }}>Meter ID</TableCell>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', verticalAlign: 'top' }}>Value</TableCell>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>Meter Date <br />
                                        <span style={{ fontSize: '12px', fontWeight: 'normal', color: 'gray' }}>(yyyy-mm-dd)</span>
                                    </TableCell>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>Meter Time <br />
                                        <span style={{ fontSize: '12px', fontWeight: 'normal', color: 'gray' }}>(hh-mm-ss in 24hrs)</span>
                                    </TableCell>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {onlineMonitoringDataList}
                            </TableBody>
                        </Table>
                        {showLoader && (
                            <Grid container justifyContent="center" className='w3-padding-24'>
                                <CircularProgress />
                            </Grid>
                        )}
                        {(!showLoader && !(onlineMonitoringData?.length > 0)) && (
                            <Grid container fullWidth justifyContent="center">
                                <Grid item>
                                    <h5 className="w3-text-grey w3-padding no-data-msg">No Data Found</h5>
                                </Grid>
                            </Grid>
                        )}
                    </TableContainer>
                </Grid>
                <Grid display={'flex'} justifyContent={'center'} className='w3-padding-16'>
                    <Button 
                        onClick={handleBackwardButtonClick} 
                        variant="outlined"
                        style={{ width: '110px', marginRight: '4px' }}
                        startIcon={<NavigateBeforeIcon />}
                        disabled={parseInt(existingParams?.page) === 1}
                    >
                        Previous
                    </Button>
                    <Button 
                        onClick={handleForwardButtonClick}  
                        variant="outlined"
                        style={{ width: '110px' , justifyContent: 'flex-end', marginLeft: '4px' }}
                        endIcon={<NavigateNextIcon />}
                        disabled={onlineMonitoringData?.length < itemsPerPage || onlineMonitoringData?.length === 0 || !onlineMonitoringData}
                    >
                    Next
                    </Button>
                </Grid>
                <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
                    <DialogTitle>Update Online Monitoring Value</DialogTitle>
                    <DialogContent style={{ paddingTop: '6px' }}>
                        <Grid container item md={12}>
                            <Grid item md={12} className='w3-margin-bottom' style={{ display: 'flex', flexDirection: 'row', }}>
                                <Grid item md={12}>
                                    <TextField
                                        required
                                        label="Value"
                                        value={onlineMonitoringValue}
                                        onChange={(e) => {handleValueChange('onlineMonitoringValue'); setOnlineMonitoringValue(e.target.value)}}
                                        fullWidth
                                    />
                                    {errors.onlineMonitoringValue && <div  className='error-text'>{errors.onlineMonitoringValue}</div>}
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <DialogActions style={{ paddingRight: '0px', justifyContent: 'center'}}>
                            <Button variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
                            <Button disabled={updateBtnDisabled} variant="contained" onClick={handlUpdateOnlinMonitoringVal} color="primary">
                                Update
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
                {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setHandleItemId({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={handleDeleteOnlineMonitoring}
                        message={'Are you sure to delete this Online Monitoring Value?'}
                    />
                ) : null}
                {downloadLoader && <Loader open={downloadLoader} msg="Downloading...Please wait...!" />}
            </Grid>

}