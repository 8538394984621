import $ from 'jquery';
import * as d3 from "d3";

export function cleanSvgIds(pid) {
    const liveViewId = `#project_live_view_chart_${pid}`;
    $(`${liveViewId}.project-live-view-chart text, ${liveViewId}.project-live-view-chart rect`).each(function (d) {
        var el_id = $(this).attr('id')
        if (el_id != undefined) {
            $(this).attr('id', el_id.replace(/_[0-9]+_/, ''))
        }
    })
}

export function insertDirectDisplayValues(item, pid) {
    const liveViewId = `#project_live_view_chart_${pid} `;
    let el = $(`${liveViewId}` + '[id="sensor-text-' + item['meterid'] + '"]').text(item['value_disp'])
    el = $(`${liveViewId}` + '[id="sensor-value-' + item['meterid'] + '"]').text(item['value_disp'])
    if (item['is_limit_exceeded'] == 1) {
        el.css('fill', 'red')
    }
}

export function insertTankValues(item, pid) {
    const liveViewId = `#project_live_view_chart_${pid} `;
    var meterid = item['meterid']
    // Display curr level in the bottom
    var text_el = $(`${liveViewId}` + '[id="tank-value-quantity-' + meterid + '"]')
    text_el.text(item['value_disp'] + ' KL')


    // Display percentage on top of the tanker
    text_el = $(`${liveViewId}` + '[id="tank-value-percentage-' + meterid + '"]')
    var perc_disp = item['tank_curr_level_per'];
    perc_disp = perc_disp == undefined || perc_disp == 'NA' ? 'NA' : perc_disp.toFixed(1) + '%'
    text_el.text(perc_disp)

    var el = $(`${liveViewId}` + '[id="tanker-rect-' + meterid + '"]')
    if (el.length > 0) {
        var obj_height = parseFloat(el.css('height').split('px')[0])
        // var obj_curr_x = parseFloat(el.css('x').split('px')[0])
        var obj_curr_y = parseFloat(el.css('y').split('px')[0])
        var req_height = item['tank_curr_level_per'] / 100;
        var obj_new_y = obj_curr_y + obj_height * (1 - req_height)
        var obj_new_height = obj_height - (obj_new_y - obj_curr_y)
        el.css('y', obj_new_y + 'px').css('height', obj_new_height + 'px')
        var fill = '#1a9850'
        if (item['tank_curr_level_per'] > 80) {
            fill = '#e46c49'
        } else if (item['tank_curr_level_per'] < 20) {
            fill = '#C8B385'
        } else {
            fill = item['value_disp'] == 'NA' ? '#FFFFFF' : '#1a9850'
        }
        el.css('fill', fill)
        d3.select('[id="tanker-rect-' + meterid + '"]').append('title').text('Tank capacity:' + Math.round(item['tank_capacity'], 1) + ' KL')
    }
}

export function showWaterFlow(item, pid) {
    const liveViewId = `#project_live_view_chart_${pid} `;
    var meterid = item['meterid']
    if (item['metername'].includes('KL/hr')) {
        var el = $(`${liveViewId}` + '[id="flow-indicator-' + meterid + '"]')
        el.addClass('pipe-water-animate')
        if (item['value'] > 0) {
            el.addClass('pipe-water-animate')
        }
        else {
            el.removeClass('pipe-water-animate')
        }

    }
}

export function showLastUpdatedTime(item, pid) {
    const liveViewId = `#project_live_view_chart_${pid} `;
    var meterid = item['meterid']
    var text_el = $(`${liveViewId}` + '[id="sensor-last-updated-time-' + meterid + '"]')
    text_el.text(item['last_updated_time_display'])
    d3.select(`${liveViewId}` + '[id="sensor-last-updated-time-' + meterid + '"]')
        .append('svg:title').text(item['last_updated_time'])

}