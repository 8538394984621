import React, { useEffect, useRef } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';

const SupersetGraph = ({ dashboardHeight, dashboardId, supersetGuestToken, dashboardType }) => {
        const dashboardRef = useRef(null);
        const gState = useSelector(st => st);

    useEffect(() => {
        embedSupersetDashboard();
    }, [dashboardId, supersetGuestToken]);

    const embedSupersetDashboard = async () => {
        if (!dashboardId) {
            return
        }
        const isAmazonDomain = gState?.allClients?.data?.totalItems === 1 && gState?.allClients?.data?.data?.some(c => c.id === 'amazon-in');

        let supersetDomain = "https://superset.greenvironment.co.in";
        if (document.domain === 'amazon.greenvironment.co.in' || document.domain === 'gaws.d2.ceegees.in' || isAmazonDomain) {
            supersetDomain = "https://amazon-superset.greenvironment.co.in";
        }

        await embedDashboard({
            id: dashboardId,
            supersetDomain,
            mountPoint: dashboardRef.current,
            fetchGuestToken: () => supersetGuestToken,
            dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: true,
                hideTab: true,
                filters: {
                    visible: false,
                    expanded: false,
                }
            }
        });
    }
    

    return (
        <div id={`superset-db-${dashboardId}`}>
            <Tooltip title="Refresh" id='refresh-icon'>
                <Button 
                    size='small'
                    color='success'
                    variant="outlined"
                    style={{position: 'relative', top: dashboardType === 'ESG' ? 56 : 0, right: 16, border: 'none'}}
                    className='w3-right w3-margin-right' 
                    onClick={() => embedSupersetDashboard()}
                >
                    <RefreshIcon />
                </Button>
            </Tooltip>
            <div ref={dashboardRef} style={{height: dashboardHeight}} className="superset-db"></div>
        </div>
    );
};

export default SupersetGraph;
