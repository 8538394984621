import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AbilityBuilder, Ability } from '@casl/ability'
import { userRoles } from './userRoles';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export function updateAbility(roles, ability, client_id, clPermissions, clientAdmin) {
  const { can, rules } = new AbilityBuilder();
  roles?.forEach(item => {
    let locationPermission = false;
    let locationViewPermission = false;
    let facilityEditOrDeletePermission = false;
    if (item?.role === userRoles.ADMIN) {
      can('view', [
        'Dashboard',
        'Client',
        'Assets',
        'Location',
        'Facility',
        'Project',
        'Live',
        'Esg',
        'ReportView',
        'GeoTagging',
        'WaterBalanceReport',
        'Meter',
        'CalibrationReport',
        'CalibrationForm',
        'SiteVisitReport',
        'SiteVisitForm',
        'Report',
        'Users',
        'Settings',
        'UserManagement',
        'Incidents',
        'Tickets',
        'TicketDetails',
        'UpdateCache',
        'WaterBalance',
        'MeterCommon',
        'DigiCalibrationForm',
        'DigiCalibrationReport'
      ]);

      can('manage', [
        'CreateClient',
        'CreateLocation',
        'CreateFacility',
        'CreateProject',

        'AddMeter',
        'AddCalibrationReport',
        'EditCalibrationReport',
        'DeleteCalibrationReport',
        'AddSiteVisitReport',
        'EditSiteVisitReport',
        'DeleteSiteVisitReport',
        'ClientDelete',
        'SaveClient',
        'ClientUserInvite',
        'EditLocation',
        'DeleteLocation',
        'MeterCommon',
        'EditFacility',
        'DeleteFacility',
        'EditProject',
        'DeleteProject',
        'EditMeter',
        'DeleteMeter',
        'OnlineMonitoring',
        'TrendingAnalysis',

        'CreateReport',
        'EditReport',
        'DeleteReport',
        'EditClientUser',
        'DeleteClientUser',
        'UserInvite',
        'CreateUsers',

        'UserEdit',
        'UserDelete',
        'CreateMeter',
        'EditUsers',

        'ActivateAndDeactivateUser',

        'EditOnlineMonitoring',
        'DeleteOnlineMonitoring'
      ]);

    } else if (item?.role === userRoles.SPOC) {
      can('view', [
        'Dashboard',
        'Client',
        'Assets',
        'Location',
        'Facility',
        'Project',
        'Live',
        'Esg',
        'ReportView',
        'GeoTagging',
        'WaterBalanceReport',
        'Meter',
        'Report',
        'Users',
        'Settings',
        'UserManagement',
        'Incidents',
        'EditProject',
        'CalibrationReport',
        'CalibrationForm',
        'SiteVisitReport',
        'SiteVisitForm',
        'OnlineMonitoring',
        'TrendingAnalysis',
        'CreateMeter',
        'EditMeter',
        'Tickets',
        'TicketDetails',
        'MeterCommon',
        'DigiCalibrationForm',
        'DigiCalibrationReport'
      ]);

      can('manage', [
        'UserInvite',
        'AddCalibrationReport',
        'EditCalibrationReport',
        'DeleteCalibrationReport',
        'EditSiteVisitReport',
        'DeleteSiteVisitReport',
        'AddSiteVisitReport'
      ]);

    } else if (item?.permission?.length > 0) {
      const permissionsMap = {
        location: ['CreateLocation', 'EditLocation', 'DeleteLocation'],
        facility: ['CreateFacility', 'EditFacility', 'DeleteFacility'],
        project: ['CreateProject', 'EditProject', 'DeleteProject'],
      };

      const enitityArray = [
        'Dashboard',
        'Location',
        'Facility',
        'Project',
        'Live',
        'Esg',
        'ReportView',
        'GeoTagging',
        'WaterBalanceReport',
      ];

      item.permission.forEach(userPermission => {
        const { name,
          permissions, type, client_id: userClient } = userPermission;
        can('view', [
          'Dashboard',
          'Client',
          'Assets',
          'Location',
          'Facility',
          'Project',
          'Live',
          'Esg',
          'ReportView',
          'GeoTagging',
          'WaterBalanceReport',
          // 'Meter',
          'Report',
          'Users',
          // 'CalibrationReport',
          'OnlineMonitoring',
          'TrendingAnalysis',
          // 'CalibrationForm',
          'SiteVisitReport',
          'SiteVisitForm',
          // 'CreateMeter',
          // 'EditMeter',
          'EditFacility',
          'EditProject',
          'CreateProject',
          'Tickets',
          'DigiCalibrationForm',
          'DigiCalibrationReport'
        ])
        if(type === 'client' && permissions.view && userClient === client_id) {
          can('view', [
            'EditLocation',
            'EditFacility',
            'EditProject'
          ]);
        }
        if(type === 'client' && permissions.edit && userClient === client_id) {
          can('view', [
            'Dashboard',
            'Client',
            'Assets',
            'Location',
            'Facility',
            'Project',
            'Live',
            'Esg',
            'ReportView',
            'GeoTagging',
            'WaterBalanceReport',
            // 'Meter',
    
            'Report',
            'Users',
            'Settings',
            // 'CalibrationReport',
            'OnlineMonitoring',
            'TrendingAnalysis',
            // 'CalibrationForm',
            'SiteVisitReport',
            'SiteVisitForm',
            'EditProject',
            'CreateProject',
            'DigiCalibrationForm',
            'DigiCalibrationReport'
          ]);
    
          can('manage', [
            'CreateUsers',
            'EditClientUser',
            'EditUsers',
            'DeleteClientUser',
            'ClientUserInvite',

            'CreateProject',
            'EditProject',
            'DeleteProject',

            'ClientDelete',
            'SaveClient',

            'EditLocation',
            'DeleteLocation',
    
            'EditFacility',
            'DeleteFacility',
          ]);

        }

        if (type in permissionsMap) {
          if (type === 'location' && (permissions.edit || permissions.delete) && userPermission.client_id === client_id && clPermissions?.includes("Facility")) {
            locationPermission = true;
          }
          if (type === 'location' && permissions.view && userPermission.client_id === client_id && clPermissions?.includes("Facility")) {
            locationViewPermission = true;
          }

          if (type === 'facility' && (permissions.edit || permissions.delete) && userPermission.client_id === client_id && clPermissions?.includes("Project")) {
            facilityEditOrDeletePermission = true;
          }
          if ((permissions.add || permissions.edit || permissions.delete) && userPermission.client_id === client_id) {
            let entityName = name;
            enitityArray.find(pArrayItem => {
              if (pArrayItem.toLowerCase() === type) {
                entityName = pArrayItem
              }
            })
            if (permissions.add) {
              can('manage', ['Create' + entityName]);
            }

            if (permissions.edit) {
              can('manage', ['Edit' + entityName]);
            }

            if (permissions.delete) {
              can('manage', ['Delete' + entityName]);
            }
          }
        }
      });
    }
    if(clientAdmin && clientAdmin?.id === item?.client?.id) {
      can('view', ['CreateUsers'])
      can('manage', ['ClientUserInvite', 'EditClientUser', 'DeleteClientUser', 'EditUsers'])
    }
    if (locationPermission) {
      can('manage', ['EditLocation', 'DeleteLocation']);
      can('manage', ['CreateFacility', 'EditFacility']);
      if (clPermissions.includes("Project")) {
        can('manage', ['CreateProject', 'EditProject', 'DeleteProject']);
      }
    }
    if(locationViewPermission) {
      can('view', ['EditLocation', 'EditFacility', 'EditProject'])
    }
    if (facilityEditOrDeletePermission) {
      can('manage', ['EditFacility', 'DeleteFacility']);
      can('manage', ['CreateProject', 'EditProject', 'DeleteProject']);
    }

    can('view', 'Settings');
    
  })

  ability?.update(rules);
}

export const getAbility = () => {
  return new Ability([]);
}
