import React, { useEffect, useState } from 'react';
import { ResponsiveSunburst } from '@nivo/sunburst'

const ProjectHierarchyChart = ({ hierarchyData }) => {
    const [sunburstData, setSunburstData] = useState(null);

    useEffect(() => {
        const data = buildSunburstData(hierarchyData)
        setSunburstData(data)
    }, [1])

    const buildSunburstData = (data) => {
        const root = { name: "root", children: [] };

        data?.forEach(({ location_name, facility_name, project_name, meter_name, count }, idx) => {
            // Location
            let location = root.children.find(loc => loc.name === location_name);
            if (!location) {
                location = { name: location_name, children: [] };
                root.children.push(location);
            }

            // Facility
            let facility = location.children.find(fac => fac.name === facility_name);
            if (!facility) {
                facility = { name: facility_name, children: [] };
                location.children.push(facility);
            }

            // Project
            let project = facility.children.find(proj => proj.name === project_name);
            if (!project) {
                project = { name: project_name, children: [] };
                facility.children.push(project);
            }
            // Meter
            project.children.push({
                name: meter_name,
                value: count
            });
        });
        return root;
    };

    return (
        <div style={{ height: '560px' }}>
            {hierarchyData?.length === 0 ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '560px' }}>
                    <h5 className='w3-text-grey'>  No Data Found</h5>
                </div>
            ) : (
                <ResponsiveSunburst
                    data={sunburstData}
                    margin={{ top: 20, right: 40, bottom: 20, left: 40 }}
                    id="name"
                    value="value"
                    borderWidth={1}
                    colors={{ scheme: 'set3' }}
                    inheritColorFromParent={false}
                    childColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'brighter',
                                0.1
                            ]
                        ]
                    }}
                    enableArcLabels={true}
                    arcLabel="value"
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                1.4
                            ]
                        ]
                    }}
                    tooltip={({ id, value }) => (
                        <strong>
                            {id}: {value}
                        </strong>
                    )}
                />
            )}
        </div>
    );
};

export default ProjectHierarchyChart;
