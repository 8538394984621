import React from 'react';
import { Modal, CircularProgress, Box, Grid } from '@mui/material';

const Loader = (props) => {
    const { open, handleClose, msg } = props;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
    return (
            <Modal
            open={open}
            onClose={handleClose}
            >
                <Box sx={style} display='flex' flexDirection='column' alignItems='center'>
                    <CircularProgress />
                    {msg ? 
                        <Grid item style={{paddingTop: '10px'}}>{msg}</Grid> 
                        : null}
                </Box>
            </Modal>
       
    );
}

export default Loader;