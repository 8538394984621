
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';

import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import { Can } from '../../Context/Can';
import ManageMeters from './ManageMeters';
import CalibrationReport from './CalibrationReport';

import { useSelector } from 'react-redux';
import OnlineMonitoring from './OnlineMonitoring';
import CalibrationList from './CalibrationList';
import TrendingAnalysis from './TrendingAnalysis';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export default function MeterCommon() {
    const { client_id, tabId, id } = useParams();
    const pid = Number(id);
    const navigate = useNavigate();
    const [value, setValue] = useState('1');

    const state = useSelector(st => st)
    const {role} = state;

    const meterQueryParams = localStorage.getItem("meterQueryParams");


    useEffect(() => {
        setValue(tabId)
      }, [tabId]);

    const handleTabSwitch = (event, newValue) => {
        setValue(newValue);
        navigate(`/client/${client_id}/meters/${newValue}/${pid}`)
      };    

    const tabNames = ['trending-analysis', 'online-monitoring', 'calibration-report', 'edit'];

    let found = false;
    for (const tabName of tabNames) {
        if (window.location.pathname.includes(tabName)) {
            found = true;
            break;
        }
    }

    if (!found) {
        navigate('/page-not-found');
    }

    return <>
        <div className='w3-padding-large'>
            <div >
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
                            <IconButton 
                            onClick={() => meterQueryParams ? navigate(`/client/${client_id}/meters?${meterQueryParams?.toString()}`) : navigate(`/client/${client_id}/meters`)}
                            color="inherit">
                                <ArrowBackIosIcon fontSize='small'/>
                            </IconButton>
                            <TabList onChange={handleTabSwitch} aria-label="lab API tabs example">
                                <Tab label="Trending Analysis" value="trending-analysis" />
                                <Tab label="Online Monitoring" value="online-monitoring" />
                                <Tab label="Calibration Report" value="calibration-report" />
                                <Tab label="Edit Meter" value="edit" />
                            </TabList>
                        </Box>
                        <TabPanel style={{ padding: '0px' }} value="trending-analysis"><Can I="view" a="TrendingAnalysis"><TrendingAnalysis /></Can> </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="online-monitoring"><Can I="view" a="OnlineMonitoring"><OnlineMonitoring /></Can> </TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="calibration-report"><Can I="view" a="CalibrationReport"><CalibrationList /> </Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="edit"><Can I="view" a="EditMeter"><ManageMeters /></Can> </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
    </>
}