import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import OpacityIcon from '@mui/icons-material/Opacity';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SpeedIcon from '@mui/icons-material/Speed';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import PieChartIcon from '@mui/icons-material/PieChart';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { Avatar, Box, Collapse, Menu, MenuItem, Tooltip, Typography, CircularProgress } from '@mui/material';
import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getClientById, getUser, getCurrentUserRole, getAllFacilities } from '../../Redux/actions';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Logout, Settings, Article, Assessment } from '@mui/icons-material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import TaskIcon from '@mui/icons-material/Task';

const drawerWidth = 275;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export default function Landing() {
  const location = useLocation();
  let params = {}
  const match = useMatch('/client/:client_id/:action/*')
  if (match) {
    params = match.params
  }
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [clientData, setClientData] = useState({})
  const [userMode, setUserMode] = useState('not-set');
  const [user, setUser] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userRole, setUserRole] = useState([])
  const openMenu = Boolean(anchorEl);
  const [trigger, setTrigger] = useState(false);
  const [clientFacilityId, setClientFacilityId] = useState(null)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };
  let navigate = useNavigate()
  const goToRoutes = (routeApi) => {
    navigate(routeApi, { replace: false });
  }
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    navigate('/login');
    window.location.reload();
  }

  const [subMenuOpen, setSubMenuOpen] = React.useState(true);
  const handleClick = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const state = useSelector(st => st);
  const { role, getClientById: cData } = state

  const handleResize = () => {
    setOpen(window.innerWidth > 800); // Adjust according to screen size
  };

  useEffect(() => {
    handleResize();

    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    dispatch(getUser()).then((resp) => {
      const response = resp?.data?.user
      if (!response) {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
          localStorage.removeItem('accessToken');
          window.location.href = '/login'
        }
        return
      }
      setUser(response)
    })
    dispatch(getCurrentUserRole()).then((userResp) => {
      setUserRole(userResp?.data?.data)
    })
  }, [trigger])

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'accessToken') {
        setTrigger(t => !t);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {

    if(params.client_id) {
      dispatch(getClientById(params.client_id)).then((resp) => {
        const response = resp?.data?.data
        if (!response) {
          return
        }
        setClientData(response)
      })
      dispatch(getAllFacilities(params.client_id)).then((resp)=> {
        const facResponse = resp?.data?.allData
        if( !facResponse ) {
          return
        }
        const facilityId = facResponse.length > 0 ? facResponse[0]?.id : null;
        setClientFacilityId(facilityId)
      })
    } else {
      setClientData(null)
    }
  }, [params.client_id])

  useEffect(() => {
    const userRoles = role?.data?.data;
    if ( userRoles?.length == 0) {
      navigate(`/page-not-found`);
      setClientList([])
      return;
    }
    if (!userRoles || userRoles.length == 0) {
      return;
    }

    const isClientIdMatch = userRoles.some(item => {
      if (item.scope == 'green') {
        return true;
      }
      if (item.client_id) {
        return item.client_id === params.client_id && !item.role
      }
      return true
    });

    const hasGreen = userRoles.some(item => item.scope == 'green');

    const cList = userRoles.filter(item => item.client).map(item => item.client);
    setUserMode(hasGreen ? 'green' : 'client');

    if (!hasGreen && !params.client_id) {
      const rl = userRoles.find(item => item.scope != 'green');
      navigate(`/client/${rl.client_id}/assets`);
    } else if (!isClientIdMatch) {
      navigate('/')
    }
    setClientList(cList)
  }, [role, JSON.stringify(params)])

  let subListPadding = { pl: 4 };
  if (userMode != 'green') {
    subListPadding = {}
  }
  const classFromPath = (check) => {
    return location.pathname.includes(check) ? 'menu-active' : ''
  }

  let clientName = '';
  if(clientData?.name) {
    clientName = clientData.name
  }
  const MainListItems = (
    <List>
      {userMode == 'green' && <>
        <ListItemButton onClick={() => { goToRoutes('/dashboard') }}
          className={(location.pathname.includes('/dashboard') || location.pathname === '/') ? 'menu-active' : ''}>
          <ListItemIcon>
            <DashboardIcon style={{ color: '#33a433' }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton onClick={() => { goToRoutes('/client-listing') }}
          className={classFromPath('/client-listing')}>
          <ListItemIcon>
            <ViewListIcon style={{ color: '#33a433' }} />
          </ListItemIcon>
          <ListItemText primary="Client Listing" />
        </ListItemButton>
      </>}

      <Collapse in={params.client_id !== undefined} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div>
            <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/assets`) }}
              className={classFromPath('/assets')}>
              <ListItemIcon>
                <HomeIcon style={{ color: 'black' }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" secondary={`${clientName}`}
                secondaryTypographyProps={{ noWrap: true }} />
            </ListItemButton>
          </div>

          <ListItemButton sx={subListPadding} onClick={() => {
            clientFacilityId ? navigate(`/client/${params.client_id}/digitalisation/${clientFacilityId}/live`, { replace: false })
              : navigate(`/client/${params.client_id}/digitalisation`, { replace: false })
          }}
            className={classFromPath('/digitalisation')}>
            <ListItemIcon>
              <TipsAndUpdatesIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Digitalisation" />
          </ListItemButton >

          {/* <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/live`) }}
            className={classFromPath('/live')}>
            <ListItemIcon>
              <SettingsInputAntennaIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Digital Twin" />
          </ListItemButton > */}

          {/* <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/report-view`) }}
            className={classFromPath('/report-view')}>
            <ListItemIcon>
              <SimCardDownloadIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton > */}

          <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/esg`) }}
            className={classFromPath('/esg')}>
            <ListItemIcon>
              <TrendingUpIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Sustainability Dashboard" />
          </ListItemButton >

          {/* { userMode === 'green' && (<ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/water-balance-report`) }}
            className={classFromPath('/water-balance-report')}>
            <ListItemIcon>
              <OpacityIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Water Balance" />
          </ListItemButton >)} */}

          <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/geo-tagging`) }}
            className={classFromPath('/geo-tagging')}>
            <ListItemIcon>
              <LocationOnIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Geo Tagging" />
          </ListItemButton >

          { userMode === 'green' && (<ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/meters`) }}
            className={classFromPath('/meters')}>
            <ListItemIcon>
              <SpeedIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Sensors" />
          </ListItemButton>)}
          <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/projects`) }}
            className={classFromPath('/projects')}>
            <ListItemIcon>
              <AccountTreeIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Digital Twin" />
          </ListItemButton >

          {/* <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/client-tickets`) }}
            className={(classFromPath(`/client/${params.client_id}/client-tickets`))}>
            <ListItemIcon>
              <TaskIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Client Tickets" />
          </ListItemButton> */}

          <ListItemButton sx={subListPadding} onClick={() => { goToRoutes(`/client/${params.client_id}/users`) }}
            className={classFromPath('/users')}>
            <ListItemIcon>
              <PeopleIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Customer Users" />
          </ListItemButton>
          <ListItemButton sx={subListPadding} onClick={() => { navigate(`/client/${params.client_id}/settings/general`, { replace: true }) }}
            className={(classFromPath(`/client/${params.client_id}/settings`))}>
            <ListItemIcon>
              <PermDataSettingIcon style={{ color: 'black' }} />
            </ListItemIcon>
            <ListItemText primary="Client Settings" />
          </ListItemButton>
        </List>
      </Collapse>

      {userMode == 'green' && <>
        <ListItemButton onClick={() => { goToRoutes('/user-management') }}
          className={classFromPath('/user-management')}>
          <ListItemIcon>
            <AdminPanelSettingsIcon style={{ color: '#33a433' }} />
          </ListItemIcon>
          <ListItemText primary="User Management" />
        </ListItemButton>

        <ListItemButton onClick={() => { goToRoutes('/system-dashboard') }}
          className={classFromPath('/system-dashboard')}>
          <ListItemIcon>
            <Assessment style={{ color: '#33a433' }} />
          </ListItemIcon>
          <ListItemText primary="System Dashboard" />
        </ListItemButton>

        <ListItemButton onClick={() => { goToRoutes('/system-logs') }}
          className={classFromPath('/system-logs')}>
          <ListItemIcon>
            <Article style={{ color: '#33a433' }} />
          </ListItemIcon>
          <ListItemText primary="System Logs" />
        </ListItemButton>

        {userMode == 'green' && (
          <ListItemButton onClick={() => { goToRoutes('/sample-client-dashboard') }}
          className={classFromPath('/sample-client-dashboard')}>
          <ListItemIcon>
            <PieChartIcon style={{ color: '#33a433' }} />
          </ListItemIcon>
          <ListItemText primary="Sample Client Dashboard" />
        </ListItemButton>
        )}

        <ListItemButton onClick={() => { goToRoutes('/tickets') }}
          className={(location.pathname.includes('/tickets') && !(location.pathname.includes('/digitalisation'))) ? 'menu-active' : ''}>
          <ListItemIcon>
            <AssignmentTurnedInIcon style={{ color: '#33a433' }} />
          </ListItemIcon>
          <ListItemText primary="Tickets" />
        </ListItemButton>

      </>}

    </List>
  )

  const settingsUrl = userMode == 'green' ? '/settings' : `/client/${params.client_id}/user-settings`;

  let logo = (
    <Grid container justifyContent={'center'}>
        <CircularProgress />
    </Grid>
  );

  if (clientData && cData?.data?.data && Object.keys(cData?.data?.data).length > 0) {
    logo = <img alt="client-logo" src={cData?.data?.data?.media?.path || GreenvironmentLogo} style={{ width: '80%', maxHeight: '50px', objectFit: 'contain' }}></img>
  } else if (!window.location?.pathname.includes('/client/')) {
    logo = <img alt="logo" src={GreenvironmentLogo} style={{ width: '80%', maxHeight: '65px', objectFit: 'contain' }}></img>
  }

  return (
    <>
      {/* <CssBaseline /> */}
      <AppBar id="landing-app-bar" className='LandingAppbar' position="absolute" open={open}>
        <Toolbar className='LandingToolbar'
          alignitems='space-between'
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography noWrap={true} sx={{ noWrap: true, textAlign: 'left', paddingLeft: '8px', flexGrow: 1 }}>
            {params?.action === "sample-client-dashboard" ? "" : clientData?.name}
          </Typography>
          <Typography sx={{ noWrap: true, alignItems: 'right', alignContent: 'right', textAlign: 'right', paddingRight: '8px', flexGrow: 1 }}>
            {user?.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'left', alignSelf: 'right', textAlign: 'left' }}>


            <Tooltip title="Account settings">
              <IconButton
                onClick={handleMenuClick}
                aria-controls={openMenu ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }} >{user?.name?.substring(0, 1)}</Avatar>
              </IconButton>
            </Tooltip>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem disabled={true}>
              {user?.uniqueId}
            </MenuItem>
            {clientList.map((item, idx) => {
              return <MenuItem key={`menu-item-${idx}`} component={Link} to={`/client/${item.id}/assets`}>{item.name}</MenuItem>
            })}
            <Divider />
            <MenuItem component={Link} to={settingsUrl}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer id="sidebar-drawer" variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          {logo}
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        {MainListItems}
      </Drawer>
    </>

  );
}