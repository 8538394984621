import React, { useEffect } from 'react';
import { Outlet, Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import CreateClient from './Dashboard/CreateClient';
import Location from './Locations/Location';
import ManageLocation from './Locations/ManageLocation';
import Facilities from './Facilities/Facilities';
import Report from './Report/Report';
import ManageFacilities from './Facilities/ManageFacilities';
import CreateReport from './Report/CreateReport';
import { Can } from '../Context/Can';


export default function ClientSettings() {
  const { client_id, tabId, action } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');
  const location = useLocation();

  useEffect(() => {
    setValue(tabId)
  }, [tabId]);

  const handleTabSwitch = (event, newValue) => {
    setValue(newValue);
    navigate(`/client/${client_id}/settings/${newValue}`)
  };

  let locationComponent = <Can I="view" a="Location"><ManageLocation /></Can>
  if(action === 'create'){
    locationComponent = <Can I="view" a="CreateLocation"><Location /></Can>
  }else if(action === 'edit'){
    locationComponent = <Can I="view" a="EditLocation"><Location /></Can>
  }

  let facilityComponent = <Can I="view" a="Facility"><Facilities /></Can>
  if(action === 'create'){
    facilityComponent = <Can I="view" a="CreateFacility"><ManageFacilities /></Can>
  }else if(action === 'edit'){
    facilityComponent = <Can I="view" a="EditFacility"><ManageFacilities /></Can>
  }

  let reportComponent = <Can I="view" a="Report"><Report /></Can>
  if(action === 'create'){
    reportComponent = <Can I="view" a="CreateReport"><CreateReport /></Can>
  }else if(action === 'edit'){
    reportComponent = <Can I="view" a="EditReport"><CreateReport /></Can>
  }

  return (
    <div className='w3-padding-large'>
        <div >
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabSwitch} aria-label="lab API tabs example">
                    <Tab label="General Settings" value="general" />
                    <Tab label="Reports" value="report" />
                    <Tab label="Locations" value="locations" />
                    <Tab label="Facilities" value="facilities" />
                </TabList>
                </Box>
                <TabPanel style={{padding:'0px'}} value="general"><CreateClient /></TabPanel>
                <TabPanel style={{padding:'0px'}} value="report">{reportComponent}</TabPanel>
                <TabPanel style={{padding:'0px'}} value="locations">{locationComponent}</TabPanel>
                <TabPanel style={{padding:'0px'}} value="facilities">{facilityComponent}</TabPanel>
                </TabContext>
            </Box>
        </div>
    </div>
  );
}
