import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Login from '../Components/Auth/Login';
import Register from '../Components/Auth/Register';
import ForgotPassword from '../Components/Auth/ForgotPassword';
import ResetPassword from "../Components/Auth/ResetPassword";
import Invite from '../Components/Auth/Invite';

export function Layout (){
    return (
       
      <BrowserRouter>
       <Routes>
       <Route path="/" element={<Login/>} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/invite/:token" element={<Invite />} />
                <Route path="/reset-password/:token" element={<ResetPassword/>} />
       </Routes>
        </BrowserRouter>
      );
}