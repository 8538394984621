import { Button, Grid, Typography, Tooltip, IconButton, Paper, TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { changeUserPassword, setStoreData, updateProfile, getCurrentUserRole } from '../Redux/actions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSearchParams } from 'react-router-dom';
import AlertDialog from './Common/AlertDialog';

const panelStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        activeButton: {
            backgroundColor: '#1c7bd9', 
            color: '#fff', 
            '&:hover': { 
                backgroundColor: '#1976d2',
            }
        },
    })
);

const Settings = (props) => {
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useSearchParams();
    const dispatch = useDispatch();
    const initForm = {
        currentPassword: '',
        password: '',
        confirm: '',
    };

    const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [passReg, setPassReg] = useState();
    const panel = panelStyles();
    const path = useLocation();
    const splitedPath = path.pathname.split('/');
    const customerId = parseInt(splitedPath[2]);
    const initErr = {};
    const [form, setForm] = useState(initForm);
    const [errors, setErrors] = useState(initErr);
    const [alertMessage, setAlertMessage] = useState();
    const [flag, setFlag] = useState();

    const state = useSelector(st => st);
    const user  = state?.user?.data?.user;

    const [disableButtonProfile, setDisableButtonProfile] = useState(false);
    const [disableButtonSettings, setDisableButtonSettings] = useState(false);
    const [msgType, setMsgType] = useState("success");
    const [profileDetails, setProfileDetails] = useState({
        newDisplayName: '',
    })

    const tabName = queryParams.get("tab");
    const [tabValue, setTabValue] = useState(tabName);

    useEffect(() => {
        dispatch(getCurrentUserRole());
    }, [])

    useEffect(() => {
        if (!queryParams.get('tab')) {
            queryParams.set('tab', 'profile');
            navigate({ search: queryParams.toString() }, { replace: true });
        }
        fetchData();
    }, [ queryParams]);

    const fetchData = () => {
        if (!customerId) {
            return;
        }
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        const fieldValue = Object.assign({}, form);
        const errorField = Object.assign({}, errors);

        if(errorField.password === "Current Password & New Password is similar"){
            errorField.password = null;
            setErrors(errorField);
        }
        if (errorField[name]) {
            errorField[name] = null;
            setErrors(errorField);
        }
        fieldValue[name] = value;
        setPassReg(false);
        setForm(fieldValue);
        if(disableButtonSettings){
            setDisableButtonSettings(false)
        }
    };

    const validateData = () => {
        let hasError = false;
        const err = { ...errors };
        if(tabName === 'profile'){
            if (profileDetails.newDisplayName === user.name){
                hasError = true;
                err.newDisplayName = 'Current Display Name  & New Display Name is Similar'
            }
            if (!profileDetails.newDisplayName || profileDetails.newDisplayName.trim() === "") {
                hasError = true;
                err.newDisplayName = 'This field is mandatory';
            }
        }else if(tabValue === 'usersettings'){
            if (form.password === form.currentPassword) {
                hasError = true;
                err.password = 'Current Password & New Password is similar';
            }
            if (form.password !== form.confirm) {
                hasError = true;
                err.confirm = 'New Password and confirm password must be same.';
            }
    
            const regex = /^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*]).{8,20}$/;
            if (!regex.test(form.password)) {
                hasError = true;
                setPassReg(true);
                err.password = `Password doesn't meet the requirements`;
            }
    
            Object.keys(form).forEach((key) => {
                if (!form[key].trim()) {
                    hasError = true;
                    err[key] = 'This field is mandatory';
                }
            });
        }
        
        if (hasError) {
            setErrors(err);
            return false;
        }
        return form;
    };

    const handleSubmit = (e) => {
        setDisableButtonSettings(true)
        e.preventDefault();
        const valid = validateData();
        if (valid) {
            dispatch(changeUserPassword(form)).then((resp) => {
                setDisableButtonSettings(false)
                const res = resp && resp.data;
                if (res && res.success) {
                    setFlag(1);
                    updateResponse(res, "Password changed successfully");
                    localStorage.removeItem('accessToken');
                    navigate('/login')
                    window.location.reload();
                } else {
                    setAlertMessage(res.data.message);
                    setMsgType("fail")
                }
            });
        }
    };

    const handleChangeProfileDetails = (e) => {
        const { name, value } = e.target;
        const data = { ...profileDetails };
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        setProfileDetails(data);
        if(disableButtonProfile){
            setDisableButtonProfile(false)
        }
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        if (msg) {
            let type = "fail";
            if (res && (res.success || resp.success)) {
                msg = message;
                type = "success";
            }
            dispatch(setStoreData('alertMessage', {
                msg, type,
                handleClose: () => handleCloseDialog(),
            }))
        }
    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        window.location.reload()
        navigate("/settings?tab=profile", { replace: true })
    }

    const handleSubmitProfileDetails = (e) => {
        setDisableButtonProfile(true)
        const valid = validateData();
        if(valid){
            const form = {
                displayName: profileDetails.newDisplayName,
            }
            if (!profileDetails.newDisplayName) {
                return;
            }
            dispatch(updateProfile(form)).then(resp => {
                setDisableButtonProfile(false)
                if (!resp) {
                    return
                } else {
                    updateResponse(resp, resp?.data?.message);
                }
            })
        }
    }

    

    const profileSettings =
        <Grid container>
            <Grid item container direction="row"   style={{ marginBottom: '25px' }}>
                <Grid item xs={12}>
                    <TextField
                        type="text"
                        fullWidth
                        required
                        name="newDisplayName"
                        label="Change Display Name"
                        variant="outlined"
                        margin="dense"
                        value={profileDetails.newDisplayName}
                        onChange={handleChangeProfileDetails}
                    />
                    {errors.newDisplayName && <div className='error-text '>{errors.newDisplayName}</div>}
                </Grid>
                <Grid container item xs={12} justifyContent='flex-end'>
                    <Button
                        disabled={disableButtonProfile}
                        variant="contained"
                        style={{textTransform: 'none', marginTop:'10px' }}
                        type="submit"
                        onClick={handleSubmitProfileDetails}
                    >
                        SUBMIT
                    </Button>
                </Grid>
            </Grid>
        </Grid>


    const userSetting = 
    <Grid container >
        <Grid item container direction="row"  style={{ marginBottom: '25px' }}>
            <Grid item xs={12}>
                <TextField
                    label="Current Password"
                    name="currentPassword"
                    fullWidth
                    value={form.currentPassword}
                    required
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setShowPasswordCurrent(!showPasswordCurrent)}>
                                {showPasswordCurrent ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            )
                    }}
                    type={showPasswordCurrent ? 'text' : 'password'}
                />
                {errors.currentPassword && <div className='error-text '>{errors.currentPassword}</div>}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="password"
                    fullWidth
                    required
                    id="outlined-basic"
                    label="New Password"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            )
                    }}
                    type={showPassword ? 'text' : 'password'}
                    
                />
                {errors.password && <div className='error-text '>{errors.password}</div>}
                {
                    passReg === true &&
                    <div className={panel.root}>
                        <Typography className="w3-text-red">
                            <li> Minimum password length 8</li>
                            <li> Maximum password length 20</li>
                            <li>Required at least one digit</li>
                            <li>Required at least one upper case</li>
                            <li> Required at least one lower case</li>
                            <li>Required at least one symbol</li>
                        </Typography>
                    </div>
                }
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="confirm"
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Confirm New Password"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                                {showPasswordConfirm ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                            )
                    }}
                    type={showPasswordConfirm ? 'text' : 'password'}
                />
                {errors.confirm && <div className='error-text '>{errors.confirm}</div>}
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end'>
                <Button
                    disabled={disableButtonSettings}
                    variant="contained"
                    style={{ marginLeft: 'auto', textTransform: "none", marginTop:'10px' }}
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                >SUBMIT
                </Button>
            </Grid>
        </Grid>
    </Grid>

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        queryParams.set('tab', newValue.toString());
        navigate({ search: queryParams.toString() }, { replace: false });
    };
    return (
        <div>
            <Grid container
                sx={{
                    padding: {
                    xs: '12px 8px', 
                    sm: '12px 24px', 
                    md: '12px 24px' 
                    }
                }}
            >
                <Grid item xs={12} container justifyContent='left' style={{ marginBottom: '15px', paddingTop: '0px' }}>
                    <Tabs
                        value={queryParams.get('tab')} 
                        onChange={handleTabChange}
                        style={{ marginRight: '20px' }}
                    >
                        <Tab
                            label="Profile"
                            value={'profile'}
                        />
                        <Tab
                            label="Manage Password"
                            value={'usersettings'}
                        />
                    </Tabs>
                </Grid>
                <Grid item container xs={12} sm={12} md={6} component={Paper} style={{paddingTop:'20px'}}
                sx={{
                    padding: {
                        xs: '24px 8px',
                        sm: '24px 24px',
                        md: '24px 24px'
                    }
                }}
                >
                    {queryParams.get('tab') === 'profile' ?
                        profileSettings : userSetting
                    }
                </Grid>
            </Grid>

            {alertMessage ? (
                <AlertDialog
                    handleClose={() => setAlertMessage(null)}
                    message={alertMessage}
                    type={msgType}
                    handleDialogClose={() => setAlertMessage(null)}
                />) : null}
        </div>
    );
};

export default Settings;

