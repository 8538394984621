import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function Incidents () {
    return(
            <Grid>
                <Box
                    component="main"
                    sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    }}
                >

                    <h1 style={{paddingTop:'50px'}}>Incidents</h1>
                </Box>
            </Grid>
    )
}