import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserProjects } from '../../Redux/actions';
import { useParams } from 'react-router-dom';

export default function GeoTagging() {
    const dispatch = useDispatch();
    const { client_id } = useParams();
    const [geoData, setGeoData] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const mapRef = useRef(null);
    const markers = useRef([]);

    useEffect(() => {
        dispatch(getUserProjects(client_id)).then((res) => {
            setShowLoader(false);
            if (res && res.status === 200) {
                const projectList = res.data.data;
                let projects = projectList.map(project => ({
                    title: project.projname.replace('WA-', ''),
                    mapurl: project.mapurl,
                }));

                for (let column of ['lat', 'lng']) {
                    projects = projects.map(project => ({
                        ...project,
                        [column]: parseFloat(project.mapurl.split(',')[column === 'lat' ? 0 : 1])
                    }));
                }

                projects = projects.filter(project => !isNaN(project.lat) && !isNaN(project.lng));

                setGeoData(projects.map(project => ({
                    lat: project.lat,
                    lng: project.lng,
                    title: project.title
                })))
            }
        });
    }, [1]);

    useEffect(() => {
        if (geoData?.length === 0) {
            return;
        }
        const mapOptions = {
            center: { lat: geoData[0].lat, lng: geoData[0].lng }, // Center the map on the first location
            zoom: 14,
        };

        const map = new window.google.maps.Map(mapRef.current, mapOptions);

        // Create markers and info windows for each location
        geoData.forEach((location) => {
            const marker = new window.google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map,
                title: location.title,
            });

            markers.current.push(marker);

            const infoWindow = new window.google.maps.InfoWindow({
                content: location.title,
            });

            marker.addListener('click', () => {
                infoWindow.open(map, marker);
            });
        });
    }, [geoData]);


    return (
        <div className="main w3-padding-large" >
            <h5 className="card-title hide-in-print">GeoTagging</h5>
            {geoData?.length === 0 && !showLoader ? (
                <div className='no-data-msg'>No Projects with location data available</div>
            ) : (
                <div
                    ref={mapRef}
                    style={{ height: '75vh', width: '100%' }}
                ></div>
            )}
        </div >
    )
}