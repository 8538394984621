import { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

export default function UpdateCacheData() {
    const [updateCache, setUpdateCache] = useState(true);

    useEffect(() => {
        updateProjectMeterlistCache();
    }, [1])

    const updateProjectMeterlistCache = async () => {
        await axios.get(`/papi/update-cache/`)
            .then((response) => {
                if (response?.data?.success) {
                    setUpdateCache(false);
                }
            })
            .catch((error) => {
                console.error('Error fetching update cache api:', error);
            });
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '500px' }}>
            {updateCache ? (
                <div>
                    <h4>Updating cache data...</h4>
                    <div className='w3-center' style={{ marginTop: 30 }}><CircularProgress /></div>
                </div>
            ) : (
                <h4>Updated cache data</h4>
            )}
        </div>
    )
}