import React from 'react';
import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import Plot from 'react-plotly.js';
import InsightsModal from '../../Common/InsightsModal';

import { Lightbulb } from '@mui/icons-material';
import { formatDate, generateInsightKey } from '../../../utils';

export default function WBTrendingAnalysisChart({ tableData, chartSummaryPoints, client_id, from_date, to_date, media_id }) {

    const [openModal, setOpenModal] = useState(false);
    const [showInsightButton, setShowInsightButton] = useState(true)
    const [insightKey, setInsightKey] = useState(null);


    const convertedJson = [];
    tableData?.forEach((obj, index) => {
        convertedJson.push(obj);
    });

    const prepareInsightModal = () => {
        //Converiting the date format to YYYY-MM-DD
        const formattedFromDate = formatDate(new Date(from_date));
        const formattedToDate = formatDate(new Date(to_date));

        const key = generateInsightKey( client_id, formattedFromDate, formattedToDate, media_id, 'wb-trending-analysis');
        setInsightKey(key);
        setOpenModal(true);
        setShowInsightButton(false)
    }

    if (!tableData) {
        return <div>
            <div style={{ fontSize: 16, margin: '28px 0 28px 0' }}>No Data Found</div>
        </div>
    }
    const allValuesZero = tableData?.every(item => item.value === 0);

    const chartConfig = {
        data: [
            {
                name: 'Fresh Water',
                x: tableData?.filter(item => item.category === 'Fresh Water').map(item => item.Date),
                y: tableData?.filter(item => item.category === 'Fresh Water').map(item => item.value),
                text: tableData?.filter(item => item.category === 'Fresh Water').map(item => item.perc_value),
                type: 'bar',
                hoverinfo: 'text',
                marker: { color: '#4682B4' },
                hovertemplate: `Category=Fresh Water<br>%{xaxis.title.text}=%{x}<br>%{yaxis.title.text}=%{y}<br>%Consumption=%{text}<extra></extra>`,
                orientation: 'v',
                textposition: 'auto',
                alignmentgroup: true,
            },
            {

                name: 'Recycled Water',
                x: tableData?.filter(item => item.category === 'Recycled Water').map(item => item.Date),
                y: tableData?.filter(item => item.category === 'Recycled Water').map(item => item.value),
                text: tableData?.filter(item => item.category === 'Recycled Water').map(item => item.perc_value),
                type: 'bar',
                hoverinfo: 'text',
                marker: { color: '#53BE04' },
                hovertemplate: 'Category=Recycled Water<br>%{xaxis.title.text}=%{x}<br>%{yaxis.title.text}=%{y}<br>%Consumption=%{text}<extra></extra>',
                orientation: 'v',
                textposition: 'auto',
                alignmentgroup: true,
            },
        ],
        layout: {
            plot_bgcolor: 'rgba(0, 0, 0, 0)',
            paper_bgcolor: 'rgba(0, 0, 0, 0)',
            autosize: true,
            barmode: 'stack',
            showlegend: false,
            xaxis: {
                showgrid: false,
                title: 'Date'
            },
            yaxis: {
                showgrid: false,
                title: 'Water Consumption (in KL)',
                showline: true,
            }
        },
    };


    return (
        <div>
            <div style={{ textAlign: 'right' }}>
                {(tableData && showInsightButton && !allValuesZero) && (<Button
                    id="trending-analysis-insight"
                    size='small'
                    variant="outlined"
                    startIcon={<Lightbulb />}
                    style={{ textAlign: 'right' }}
                    onClick={prepareInsightModal}>
                    View Insights
                </Button>)}
            </div>
            <Plot
                data={chartConfig.data}
                layout={chartConfig.layout}
                style={{
                    height: '100%',
                    width: '100%',
                }}
            />
            <div className="row">
                <div className="offset-md-3">
                    <div className="legend-water-balance">
                        Color legend:
                        <svg width="20" height="10">
                            <rect x="0" width="20" height="10" fill="#4682B4" />
                        </svg>
                        <span>Fresh Water</span>
                        <svg width="20" height="10">
                            <rect x="0" width="20" height="10" fill="#53BE04" />
                        </svg>
                        <span>Recycled Water</span>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: 20 }} className="col-md-12" id="chart-trend-breakup-insights">
                {chartSummaryPoints?.map((point, index) => (
                    <li key={index}>{point}</li>
                ))}
            </div>

            {/* Use the InsightsModal component */}
            <InsightsModal
                convertedJson={convertedJson}
                openInsight={openModal}
                closeInsight={()=> setOpenModal(false)}
                setShowInsightButton={() => setShowInsightButton(true)}
                insightKey={insightKey}
            />
        </div>
    );
}