import LandingImage from '../../../src/Images/green-landing-image.png';
import LandingBannerLeft from '../../../src/Images/green-banner-1.png';
import LandingBannerRight from '../../../src/Images/green-banner-2.png';



const LandingPage = ({ children }) => {
    return <>
        <div className='gv-landing-page'>
            <img className='gv-landing-left' alt="gv-landing" src={LandingBannerLeft} />
            <img className='gv-landing-right' alt="gv-landing" src={LandingBannerRight} />
            <img className='gv-landing-image w3-hide-small w3-hide-medium' alt="gv-landing" src={LandingImage} />
            {children}
        </div>
    </>
}
export default LandingPage