import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './Redux/Reducer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { AbilityContext, getAbility } from './Context/Can';

const defaultTheme = createTheme();

const store = createStore(reducer, applyMiddleware(thunk));
window.gStore = store;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={defaultTheme}>
      <AbilityContext.Provider value={getAbility()} >
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </AbilityContext.Provider>
    </ThemeProvider>
  </Provider>
);


reportWebVitals();
