import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { Button, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {isValidDate} from '../../utils/Common';
import ReportView from './ReportView';


export default function DigiReportView({ pids }) {
    const [errors, setErrors] = useState([])
    const [htmlContent, setHtmlContent] = useState([])
    const minDate = dayjs().subtract(180, 'days').toDate();
    const maxDate = dayjs().subtract(1, 'days').toDate();
    const [generateReport, setGenerateReport] = useState(false);
    
    const [reportFromDate, setReportFromDate] = useState(
        dayjs().subtract(14, 'days').toDate()
        );
        const [reportToDate, setReportToDate] = useState(maxDate);

    useEffect(() => {
        const divElements = document.getElementsByClassName('trigger-pdf-save');
        if (divElements?.length > 0) {
            for (const divElement of divElements) {
                divElement.addEventListener('click', handleSaveReport);
            }

            return () => {
                for (const divElement of divElements) {
                    divElement.removeEventListener('click', handleSaveReport);
                }
            };
        }
    }, [htmlContent]);

    const handleSaveReport = () => {
        const css = '@page { size: landscape; }';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.media = 'print';
        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);
        window.print()
    }

    const validateForm = () => {
        let formIsValid = true;
        let err = { ...errors };

        if (!reportFromDate) {
            formIsValid = false;
            err.reportFromDate = 'Please select report From Date';
        }

        if (!reportToDate) {
            formIsValid = false;
            err.reportToDate = 'Please select report To Date';
        }

        if (moment(reportFromDate).format('YYYY-MM-DD') > moment(reportToDate).format('YYYY-MM-DD')) {
            formIsValid = false;
            err.reportFromDate = 'From Date should be less than To Date';
        }

        if (!isValidDate(reportFromDate)) {
            formIsValid = false;
            err.reportFromDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (!isValidDate(reportToDate)) {
            formIsValid = false;
            err.reportToDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }
        
        setErrors(err);
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setHtmlContent('<div style="margin-top: 10px">No Data Found</div>')
        const valid = validateForm();
        if (!valid) {
            return;
        }
        setGenerateReport(true);

    }

    const handleReportGenerated = () => {
        setGenerateReport(false);
    };

    return (
        <div className="main w3-padding-large" style={{ marginTop: 20 }}>
            <div className='report-view'>
                <div className="reports-header hide-in-print" style={{ paddingLeft: '24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                <DatePicker
                                    label="From Date *"
                                    dateFormat="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    required
                                    className='report-view-calendar-icon'
                                    slotProps={{ textField: { fullWidth: true, error: false, size: 'small' } }}
                                    name="reportFromDate"
                                    value={dayjs(reportFromDate)}
                                    maxDate={dayjs(maxDate)}
                                    minDate={dayjs(minDate)}
                                    onChange={(newValue) => {
                                        setReportFromDate(dayjs(newValue).toDate());
                                        setErrors({ ...errors, reportFromDate: null, reportToDate: null });
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                <DatePicker
                                    label="To Date *"
                                    dateFormat="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    required
                                    className='report-view-calendar-icon'
                                    slotProps={{ textField: { fullWidth: true, error: false, size: 'small' } }}
                                    name="reportToDate"
                                    value={dayjs(reportToDate)}
                                    minDate={dayjs(minDate)}
                                    maxDate={dayjs(maxDate)}
                                    onChange={(newValue) => {
                                        setReportToDate(dayjs(newValue).toDate());
                                        setErrors({ ...errors, reportToDate: null, reportFromDate: null });
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <Button
                                size='small'
                                color='primary'
                                style={{padding: 7}}
                                variant="contained"
                                onClick={handleSubmit}
                            >
                                Generate Report
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <Button
                                size='small'
                                color='primary'
                                style={{ padding: 7, backgroundColor: 'white' }}
                                variant="outlined"
                                onClick={handleSaveReport}
                            >
                                Save as PDF
                            </Button>

                        </Grid> 
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            {errors.reportFromDate && <div className='error-text w3-margin-bottom'>{errors.reportFromDate}</div>}
                        </Grid>
                        <Grid item md={3}>
                            {errors.reportToDate && <div className='error-text w3-margin-bottom'>{errors.reportToDate}</div>}
                        </Grid>
                    </Grid>
                </div>
                { 
                    (
                        pids.map((pid, index) => (
                            <React.Fragment key={pid}>
                                <ReportView 
                                dPid={pid} 
                                index={index} 
                                digiReportFromDate={reportFromDate} 
                                digiReportToDate={reportToDate} 
                                isDigiReportView={true} 
                                generateReport={generateReport}
                                onReportGenerated={handleReportGenerated}
                                />
                                {index !== pids.length - 1 && <hr />}
                            </React.Fragment>
                        ))
                    )
                }
            </div>
        </div >
    )
}