import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useBackActionDetection() {
    const location = useLocation();
    const prevLocation = useRef(location);
    const [isBackAction, setIsBackAction] = useState(false);

    useEffect(() => {
        const handlePopState = () => {
            setIsBackAction(true);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        if (prevLocation.current.key !== location.key) {
            setIsBackAction(false);
            prevLocation.current = location;
        }
    }, [location]);

    return isBackAction;
}
