
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SocialLogin from './SocialLogin';
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { postLogin } from '../../Redux/actions';
import { postResetPassword } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog';
import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo.svg'
import { useParams } from 'react-router-dom';
import { tokenValidation } from '../../Redux/actions';
import LandingPage from '../Common/LandingPage';
import { Card, CardHeader, CardContent } from '@mui/material';
import { CircularProgress } from '@mui/material';





const defaultTheme = createTheme();

const TokenValidationCard = (props) => {
  const { cardMsg, cardTitle } = props;
  return (
    <Card >
      <Grid container alignItems="center" direction="column" >
        <CardHeader title={cardTitle} />
        <CardContent>
          <Typography component="h5" >
            {cardMsg}
          </Typography>
        </CardContent>
      </Grid>
    </Card>
  )
}

export default function ResetPassword() {

  const initForm = {
    password: '',
    confirm: '',
  };
  const navigate = useNavigate()
  const currentUrl = window.location.href;
  const resetPasswordToken = currentUrl.split('/').pop();
  const dispatch = useDispatch();
  const initErr = {};
  const [form, setForm] = useState(initForm);
  const [errors, setErrors] = useState(initErr);
  const [alertMessage, setAlertMessage] = useState();
  const [passReg, setPassReg] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [userTokenStatus, setUserTokenStatus] = useState(true);
  const [msgType, setMsgType] = useState("success");
  const [showLoader, setShowLoader] = useState(true);
  let { token: token } = useParams()

  const state = useSelector(st => st)
    const { user } = state;

  const handleChange = (e) => {
    const { value, name } = e.target;
    const fieldValue = Object.assign({}, form);
    const errorField = Object.assign({}, errors);
    if (errorField[name]) {
      errorField[name] = null;
      setErrors(errorField);
    }
    fieldValue[name] = value;
    setPassReg(false);
    setForm(fieldValue);
  };

  const passwordValidator = (password) => {
    const pattern = new RegExp(/^(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)(?=.*[!@#$%^&*]).{8,20}$/);
    return pattern.test(password);
  }

  useEffect(() => {
    setShowLoader(true)
    dispatch(tokenValidation(token)).then((res) => {
      if (!res.data.success) {
        setAlertMessage(res.data.message);
        if(localStorage.getItem('accessToken')) {
          navigate('/')
        } else {
          setUserTokenStatus(false);
          setMsgType("fail");
        }
      }
      if(user?.data?.success && (res?.data?.uniqueId !== user?.data?.user?.uniqueId)) {
        navigate('/')
      }

    });
    setShowLoader(false);
  }, [ user, navigate, token])

  const validateData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (form.password !== form.confirm) {
      hasError = true;
      err.confirm = "New Password and confirm password must be same."
    }

    if (!passwordValidator(form.password)) {
      hasError = true;
      setPassReg(true);
      err.password = `Password doesn't meet the requirements`;
    }

    Object.keys(form).forEach((key) => {
      if (!form[key]) {
        hasError = true;
        err[key] = 'This field is mandatory';
      }
    });
    if (hasError) {
      setErrors(err);
      return false;
    }
    return form;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateData();
    if (valid) {
      valid.token = resetPasswordToken;
      dispatch(postResetPassword(valid)).then((resp) => {
        const res = resp && resp.data;
        if (res && res.success) {
          localStorage.removeItem('accessToken');
          setAlertMessage('Password reset successfully. Login now...!')
          setMsgType("success");
        } else if (res && !res.success) {
          setErrors(res.data);
          setAlertMessage(res.message)
          setMsgType("fail");
        }
      });
    }
  }

  const handleCloseDialog = () => {
    setAlertMessage(null);
    navigate('/login')
  }

  if (showLoader) {
    return <div className='w3-center' style={{ marginTop: 30 }}>
      <CircularProgress />
    </div>
  } else if (!userTokenStatus) {
    return <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#1976d2' }}>
      <Grid item xs={8}>
        <TokenValidationCard
          cardTitle={'Reset password request expired'}
          cardMsg={'Your reset password request link has expired, please send a new request to reset your password.'}
        />
      </Grid>
    </Grid>
  }

  return (
    <LandingPage>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs" style={{ position:'relative' }}>
          <CssBaseline />
          <Box
            className="landing-form"
            padding={2}
          >
            <img src={GreenvironmentLogo} style={{ marginTop: '50px', height: "120px" }} />
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
            <Typography component="h6" variant="h6" style={{ marginTop: 18, fontWeight: 'bold' }}>
              SUSTAINABILITY DASHBOARD
            </Typography>
            <Typography style={{ marginTop: 20 }}>
            Reset Password
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>

                <Grid item xs={12} className='position-relative'>
                  <input
                    className='custom-input-style'
                    name="password"
                    placeholder="New Password*"
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                  />
                  <IconButton size='small' className='password-eye-icon' onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                  {errors.password && <div className='error-text '>{errors.password}</div>}
                  {
                    passReg === true &&
                    <div>
                      <Typography className="w3-text-red">
                        <li> Minimum password length 8</li>
                        <li> Maximum password length 20</li>
                        <li>Required at least one digit</li>
                        <li>Required at least one upper case</li>
                        <li> Required at least one lower case</li>
                        <li>Required at least one symbol</li>
                      </Typography>
                    </div>
                  }
                </Grid>
                <Grid item xs={12} className='position-relative'>
                  <input
                    name="confirm"
                    placeholder="Confirm Password*"
                    onChange={handleChange}
                    type={showPasswordConfirm ? 'text' : 'password'}
                    className='custom-input-style'
                  />
                  <IconButton size='small' className='password-eye-icon' onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}>
                    {showPasswordConfirm ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                  {errors.confirm && <div className='error-text '>{errors.confirm}</div>}
                </Grid>
              </Grid>
              <Button
                type="submit"
                className='signup-btn'
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => handleSubmit(e)}
              >
                Reset Password
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login" variant="body2">
                    Back to Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {alertMessage
            ? (
              <AlertDialog
                handleClose={handleCloseDialog}
                type={msgType}
                message={alertMessage}
                handleDialogClose={() => setAlertMessage(null)}
              />
            ) : null}
        </Container>
      </ThemeProvider>
    </LandingPage>
  );
}