import { Typography, Grid, Card, CardHeader, CardContent, CardActions, Button, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getInviteStatusByToken } from '../../Redux/actions';
import Register from './Register'


const InviteMsgCard = (props) => {
    const navigate = useNavigate()
    const { cardMsg, cardTitle, showActions } = props;
    return (
        <Card >
            <Grid container alignItems="center" direction="column" >
                <CardHeader title={cardTitle} />
                <CardContent>
                    <Typography component="h5" >
                        {cardMsg}
                    </Typography>
                </CardContent>
            </Grid>
        </Card>
    )
}


const Invite = () => {
    const { token, client_id } = useParams();
    const accessToken = localStorage.getItem('accessToken')
    const dispatch = useDispatch();
    const [userTokenStatus, setUserTokenStatus] = useState(true)
    const [userTokenStatusMsg, setUserTokenStatusMsg] = useState('')
    const [showLoader, setShowLoader] = useState(false);
    const [inviteStatus, setInviteStatus] = useState(null);

    useEffect(() => {
        setShowLoader(true);
        const form = {
            accessToken
        }
        dispatch(getInviteStatusByToken(token, form)).then((res) => {
            setShowLoader(false);
            const response = res?.data?.data;
            setInviteStatus(response)
            if (!response) {
                return
            }
            setUserTokenStatus(res?.data?.success);
            setUserTokenStatusMsg(res?.data?.message);
        })
    }, [token, accessToken])

    if(showLoader) {
        return <div className="w3-center">
        <CircularProgress />
    </div>
    }

    if(accessToken && token && userTokenStatus) {
        document.location.href = `/dashboard`;
    } else  if (!userTokenStatus) {
        return <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#1976d2' }}>
            <Grid item xs={8}>
                <InviteMsgCard
                    cardTitle={`${userTokenStatusMsg === 'Token expired' ? 'Invitation expired' : 'You are not authorized'}`}
                    cardMsg={`${userTokenStatusMsg === 'Token expired' ? 'Invitation got expired' : 'You are not authorized'}, for further details please contact admin`}
                />
            </Grid>
        </Grid>
    } else if (inviteStatus) {
        localStorage.setItem('inviteToken', token)
        document.location.href = `/login`;
    } else {
        return <Register invite={true} />
    }
}
export default Invite