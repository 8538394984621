import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useState } from 'react'
import Button from '@mui/material/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo.svg'
import SocialLogin from './SocialLogin';
import { acceptInvite, postLogin } from '../../Redux/actions';
import LandingPage from '../Common/LandingPage';

const defaultTheme = createTheme();

export default function Login() {

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const initForm = {
    username: '',
    password: '',
  };
  const initErr = {};
  const [form, setForm] = useState(initForm);
  const [errors, setErrors] = useState(initErr);
  const [showPassword, setShowPassword] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'accessToken') {
        setTrigger(t => !t);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const emailValidator = (email) => {
    const pattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i);
    return pattern.test(email);
  }

  const validateData = () => {
    let hasError = false;
    const err = Object.assign({}, errors);
    if (!emailValidator(form.username)) {
      hasError = true;
      err.username = 'Enter a valid email.';
    }
    Object.keys(form).forEach((key) => {
      if (typeof (form[key]) === 'string' && key !== 'password' && key !== 'confirm') {
        if (!form[key].match(/\w/)) {
          hasError = true;
          err[key] = 'This field is mandatory';
        }
      }
      if (!form[key]) {
        hasError = true;
        err[key] = 'This field is mandatory';
      }
    });
    if (hasError) {
      setErrors(err);
      return false;
    }
    return form;
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const fieldValue = Object.assign({}, form);
    const errorField = Object.assign({}, errors);
    if (errorField[name]) {
      errorField[name] = null;
    }
    if (errorField['loginErr']) {
      errorField['loginErr'] = null;
    }
    setErrors(errorField);
    fieldValue[name] = value;
    if (name === 'username') {
      fieldValue[name] = value.toLowerCase();
    }
    setForm(fieldValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = validateData();
    if (valid) {
      dispatch(postLogin(valid)).then((resp) => {
        const res = resp && resp.data;
        const inviteToken = localStorage.getItem('inviteToken')
        const err = {
          loginErr: null
        }
        setErrors(err);
        if (res.success) {
          if (inviteToken) {
            const inviteForm = {
              token: inviteToken,
              emailId: form.username
            }
            dispatch(acceptInvite(inviteForm));
            localStorage.removeItem('inviteToken')
          }
          localStorage.setItem('accessToken', res.access_token);
          navigate('/dashboard')
          window.location.reload();
        }
        else {
          const err = {
            loginErr: res.message
          }
          setErrors(err);
        }
      });
    }
  };

  if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken') !== 'undefined') {
    navigate('/dashboard');
    window.location.reload();
    return null;
  }

  return (
    <LandingPage>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs" style={{ position:'relative' }}>
          <CssBaseline />
          <Box
            className="landing-form"
            padding={2}
          >
            <img alt="green-logo" src={GreenvironmentLogo} style={{ marginTop: '50px', height: "120px" }} />
            {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            
            <LockOutlinedIcon />
          </Avatar> */}
            <Typography component="h6" variant="h6" style={{ marginTop: 18, fontWeight: 'bold' }}>
              SUSTAINABILITY DASHBOARD
            </Typography>
            <Typography style={{ marginTop: 20 }}>
              Please Login
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3 }}>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <input
                    label="Email" 
                    id="Email"
                    name="username"
                    autoFocus
                    placeholder='Email*'
                    value={form.username}
                    onChange={handleChange}
                    className='custom-input-style'
                  />
                  {errors.username && <div className='error-text '>{errors.username}</div>}
                </Grid>
                <Grid item xs={12} className='position-relative'>
                  <input
                    id='password'
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={form.password}
                    onChange={handleChange}
                    errors={errors.password}
                    placeholder='Password*'
                    className='custom-input-style'
                  />
                   <IconButton size="small" className='password-eye-icon' onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  {(errors.loginErr || errors.password) && <div className='error-text '>{errors.loginErr ? errors.loginErr : errors.password}</div>}
                </Grid>

              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="signup-btn"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e) => handleSubmit(e)}
              >
                Login
              </Button>
              {/* <Grid container justifyContent="flex-end">
              <Grid item>
                <Link  to="/register" variant="body2">
                Don't have an account ? Sign Up
                </Link>
              </Grid>
            </Grid> */}
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/forgot-password" variant="body2">
                    Forgot Password
                  </Link>
                </Grid>
              </Grid>
              <Grid>
                <SocialLogin />
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </LandingPage>
  );
}