import React from 'react';
import Plot from 'react-plotly.js';

export default function TrendingAnalysisChart({ tableData, svgHeight, npreviousDays, meterProps, chartSummaryPoints, chartType }) {
  const svgWidth = 1100
  if (tableData?.length === 0) {
    return
  }

  const uniqueTableData = tableData.reduce((acc, current) => {
    if (!acc.find(item => item.Date === current.Date)) {
      acc.push(current);
    }
    return acc;
  }, []);
  
  const minValue = meterProps.min;
  const maxValue = meterProps.max;
  let min_limit_annot = '';
  let max_limit_annot = '';
  if (minValue) {
    min_limit_annot = `Min Acceptable limit=${minValue}`;
  }

  if (maxValue) {
    max_limit_annot = `Max Acceptable limit=${maxValue}`;
  }

  const minMaxLine = [
    {
      type: 'line',
      xref: 'paper',
      x0: 0,
      x1: 1,
      yref: 'y',
      y0: minValue,
      y1: minValue,
      line: {
        color: 'gray',
        width: 1,
        dash: 'dash',
      },
    },
    {
      type: 'line',
      xref: 'paper',
      x0: 0,
      x1: 1,
      yref: 'y',
      y0: maxValue,
      y1: maxValue,
      line: {
        color: 'gray',
        width: 1,
        dash: 'dash',
      },
    },
  ]

  const layout = {
    plot_bgcolor: 'rgba(0, 0, 0, 0)',
    paper_bgcolor: 'rgba(0, 0, 0, 0)',
    autosize: true,
    xaxis: {
      showgrid: true,
      linecolor: 'gray',
      showline: true,
      linewidth: 1,
      gridcolor: 'lightgray',
      griddash: 'dash',
      title: '',
      tickangle: npreviousDays === 0 || npreviousDays === 1  ? -45 : 0,
    },
    yaxis: {
      // anchor: 'x',
      // range: [minYValue, maxYValue],
      showgrid: true,
      linecolor: 'gray',
      showline: true,
      linewidth: 1,
      gridcolor: 'lightgray',
      griddash: 'dash',
      title: '',
    },
    hovermode: 'closest',
    annotations: [
      {
        showarrow: false,
        text: min_limit_annot,
        x: 1,
        xanchor: 'right',
        xref: 'x domain',
        y: minValue,
        yanchor: 'bottom',
        yref: 'y',
      },
      {
        showarrow: false,
        text: max_limit_annot,
        x: 1,
        xanchor: 'right',
        xref: 'x domain',
        y: maxValue,
        yanchor: 'bottom',
        yref: 'y',
      }
    ],
    shapes: minMaxLine,
  };

  const data = uniqueTableData.map(item => ({
    x: [item.Date],
    y: [item.Value],
    type: chartType === 'bar' ? 'bar' : 'scatter',
    mode: chartType,
    hoverinfo: 'text',
    hovertemplate: `Date=%{x}<br>Value=%{y}<br>Remarks=${item?.remarks || "NA"}<extra></extra>`,
    line: {
      color: item.bar_color_category === 'within_limits' ? 'steelblue' :
        item.bar_color_category === 'non_extreme_limit' ? '#EACFC2' : '#E98769',
      width: 2,
    },
    marker: {
      color: item.bar_color_category === 'within_limits' ? 'steelblue' :
        item.bar_color_category === 'non_extreme_limit' ? '#EACFC2' : '#E98769',
    },
    showlegend: false,
  }));
  let commonLayout = layout;
  if (chartType === 'line') {
    commonLayout = {
      ...commonLayout,
      shapes: [
        ...uniqueTableData.map((item, index) => ({
          type: 'line',
          x0: item.Date,
          y0: item.Value,
          x1: uniqueTableData[index + 1]?.Date,
          y1: uniqueTableData[index + 1]?.Value,
          line: {
            color:
              item.bar_color_category === 'within_limits'
                ? 'steelblue'
                : item.bar_color_category === 'non_extreme_limit'
                  ? '#EACFC2'
                  : '#E98769',
            width: 2,
          },
        })).slice(0, -1),
        minMaxLine[0],
        minMaxLine[1]
      ]
    }
  }

  return (
    <div>
      <Plot
        data={data}
        layout={commonLayout}
        style={{
          height: 370,
          width: '100%'
        }}
      />
      <div className="col-md-12" id="chart-trend-breakup-insights">
        {chartSummaryPoints.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </div>
      <div className="row">
        <div style={{ marginTop: 20 }}>
          <div className="legend-water-balance">
            Color legend:
            <svg width="20" height="10">
              <rect x="0" width="20" height="10" fill="#072C63" />
            </svg>
            <span>Value within limits</span>
            <svg width="20" height="10">
              <rect x="0" width="20" height="10" fill="#EBCFC2" />
            </svg>
            <span>Value outside the limit</span>
            <svg width="20" height="10">
              <rect x="0" width="20" height="10" fill="#FC9272" />
            </svg>
            <span>Value extremely outside the limit</span>
          </div>
        </div>
      </div>
    </div>
  );
}