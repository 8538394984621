export const userRoles = {
    ADMIN: 'admin',
    SPOC: 'spoc',
    OWNER: 'owner',
    EDITOR: 'editor',
    VIEWER: 'viewer',
    CUSTOMER_ADMIN: 'customer_admin',
    PATCH_ADMIN : 'patch_admin',
    SERVICE_USER: 'service_user',
    CUSTOMER_ADMIN: 'customer_admin',
    TEAM_LEAD: 'team_lead',
    TEAM_MEMBER: 'team_member',
    PRIMARY_POC: 'primary_poc',
    SECONDARY_POC: 'secondary_poc'
}
