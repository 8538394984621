import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClientById, getSupersetDashboards, getUserProjects } from '../../Redux/actions';
import { useParams } from 'react-router-dom';
import SupersetDashboard from './SupersetDashboard';
import { Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ETP_ENERGY_TAGS, ETP_OPEX_COST_TAGS, ETP_WATER_BALANCE_TAGS, STP_ENERGY_TAGS, STP_OPEX_COST_TAGS, STP_WATER_BALANCE_TAGS,
    STP_WQPI_TAGS, WTP_ENERGY_TAGS, WTP_WATER_BALANCE_TAGS, WTP_WQPI_TAGS } from '../../utils';

export default function ESGView() {
    const dispatch = useDispatch();
    const { client_id } = useParams();
    const state = useSelector(st => st);
    const { supersetDashboards, userProjectsList } = state
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [clientFacilities, setClientFacilities] = useState([])
    const [dashboardList, setDashboardList] = useState([])
    const [selectedDashboards, setSelectedDashboards] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([])
    const [userProjects, setUserProjects] = useState([])
    const [showDashboard, setShowDashboard] = useState(false);
    const [loadDashboard, setLoadDashboard] = useState(false);
    const [clientFound, setClientFound] = useState(true);
    const [tagTextDashboardList, setTagTextDashboardList] = useState([]);

    const dashboardTagMapping = {
        'STP Water Balance': STP_WATER_BALANCE_TAGS,
        'STP Energy Index (KW/KL)': STP_ENERGY_TAGS,
        'STP OPEX Cost Index (INR/KL)': STP_OPEX_COST_TAGS,
        'STP Water Quality Parameters Index (%)': STP_WQPI_TAGS,
        'WTP Water Balance': WTP_WATER_BALANCE_TAGS,
        'WTP Energy Index (KW/KL)': WTP_ENERGY_TAGS,
        'WTP Water Quality Parameters Index (%)': WTP_WQPI_TAGS,
        'ETP Water Balance': ETP_WATER_BALANCE_TAGS,
        'ETP Energy Index': ETP_ENERGY_TAGS,
        'ETP OPEX Cost Index': ETP_OPEX_COST_TAGS,
    };
    useEffect(() => {
        dispatch(getClientById(client_id)).then((resp) => {
            const response = resp?.data?.data
            if (!response) {
                setClientFound(false)
                return
            }
            if (response?.facilities) {
                setClientFacilities(response.facilities)
            }
        });

        dispatch(getSupersetDashboards()).then((resp) => {
            if (!resp?.data?.data || resp.data?.data?.length === 0) {
                return;
            }
            const dashboards = resp?.data?.data?.map(({ value, label }) => ({
                value,
                label: label.replace('ESG - ', '')
            }));
            setDashboardList(dashboards);
        });
        dispatch(getUserProjects(client_id));
    }, [1]);

    useEffect(() => {
        const facilityProjects = userProjectsList?.data?.data?.filter((item) => selectedFacilities.includes(item?.facilities?.id));
        const newList = facilityProjects?.reduce((acc, item) => {
            if (selectedProjects?.includes(item.pid)) {
                acc.push(item.pid);
            }
            return acc;
        }, []);
        newList && setSelectedProjects(newList)
        setUserProjects(facilityProjects);
    }, [selectedFacilities])

    useEffect(() => {
        let meterTags = new Set();
        selectedProjects?.map(pid => {
            const project = userProjects.find(proj => proj.pid === pid);
            project?.projectMeter?.forEach(item => {
                item?.meter?.tags?.forEach(meterTag => {
                    meterTags.add(meterTag.tag_text);
                });
            });
            return project?.planttype;
        });
        const uniqueMeterTags = Array.from(meterTags);
        const newDashboardList = dashboardList.filter(dashboard => {
            const tags = dashboardTagMapping[dashboard.label] || [];
            return tags.some(tag => uniqueMeterTags.includes(tag));
        });
        const filteredDashboardList = selectedDashboards.filter(value => newDashboardList.find(item => item.value === value));
        setTagTextDashboardList(newDashboardList);
        setSelectedDashboards(filteredDashboardList);
    },[selectedProjects])

    const handleDbChange = (event, name) => {
        const { target: { value } } = event;
        if (selectedProjects.length === 0 || selectedFacilities.length === 0 || selectedDashboards.length === 0) {
            setShowDashboard(false);
        }
        const newValue = typeof value === 'string' ? value.split(',') : value;

        if (name === 'project') {
            setSelectedProjects(newValue);
        } else if (name === 'facility') {
            setSelectedFacilities(newValue);
        } else {
            setSelectedDashboards(newValue);
        }
    }

    const handleShowDashboard = () => {
        setShowDashboard(true);
        setLoadDashboard(!loadDashboard);
    };

    const handleShowDashboardDropdown = () => {
        return <FormControl  size='small' sx={{ width: '100%' }}>
            <InputLabel id="chart">Dashboard*</InputLabel>
            <Select
                labelId="chart"
                id="chartId"
                className='white-bg'
                multiple
                value={selectedDashboards}
                onChange={(e) => handleDbChange(e, 'dashboard')}
                input={<OutlinedInput label="Dashboard" />}
                renderValue={(selected) => selected.length == dashboardList.length ? 'All Dashboards' : selected.length + ' selected '}
            >
                {selectedProjects?.length > 0 && tagTextDashboardList?.map((db) => (
                        <MenuItem key={db.value} label={db.label} value={db.value}>
                            <Checkbox checked={selectedDashboards.indexOf(db.value) >= 0} />
                            <ListItemText primary={db.label} />
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    }

    if (supersetDashboards?.isFetching) {
        return (
            <div className="main w3-padding-large">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h5 className="card-title">Sustainability Dashboard</h5>
                    </Grid>
                    <div className='w3-center' style={{ marginTop: 30 }}>
                        <CircularProgress />
                    </div>
                </Grid>
            </div>
        )
    }
    const isSubmitDisabled =  selectedFacilities?.length === 0 || selectedDashboards?.length === 0 || selectedProjects?.length === 0

    if (!clientFound) {
        return <Grid container justifyContent={"center"} style={{ marginTop: '160px' }}>
            <h5 className='w3-text-grey'>  No Data Found</h5>
        </Grid>
    }

    return (
        <div className="main w3-padding-large">
            <div>
                <Grid container spacing={2}>
                    <Grid item className='w3-margin-bottom'>
                        <h5 className="card-title">Sustainability Dashboard</h5>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='w3-margin-bottom'>
                    <Grid item md={3}>
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="facility">Facility*</InputLabel>
                            <Select
                                labelId="facility"
                                id="facilityId"
                                className='white-bg'
                                multiple
                                value={selectedFacilities}
                                onChange={(e) => handleDbChange(e, 'facility')}
                                input={<OutlinedInput label="Facility" />}
                                renderValue={(selected) => selected.length == clientFacilities.length ? 'All Facilities' : selected.length + ' selected '}
                            >
                                {clientFacilities.map((fac) => (
                                    <MenuItem key={fac.id} label={fac.facility_name} value={fac.id}>
                                        <Checkbox checked={selectedFacilities.indexOf(fac.id) >= 0} />
                                        <ListItemText primary={fac.facility_name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={3} >
                        <FormControl size='small' sx={{ width: '100%' }}>
                            <InputLabel id="chart">Project*</InputLabel>
                            <Select
                                labelId="chart"
                                id="chartId"
                                className='white-bg'
                                multiple
                                value={selectedProjects}
                                onChange={(e) => handleDbChange(e, 'project')}
                                input={<OutlinedInput label="Project" />}
                                renderValue={(selected) => selected.length == userProjects.length ? 'All Projects' : selected.length + ' selected '}
                            >
                                {userProjects?.filter((item) => selectedFacilities.includes(item.facilities.id)).map((proj) => (
                                    <MenuItem key={proj.pid} label={proj.projname} value={proj.pid}>
                                        <Checkbox checked={selectedProjects.indexOf(proj.pid) >= 0} />
                                        <ListItemText primary={proj.projname.replace("WA-", "").trim()} />
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={3}>
                        {handleShowDashboardDropdown()}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size='small'
                            style={{cursor: isSubmitDisabled ? 'not-allowed' : 'pointer', height: '98%'}}
                            onClick={handleShowDashboard}
                            disabled={isSubmitDisabled}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </div>
            {selectedFacilities?.length === 0 && (
                <div>
                    Select a facility from the dropdown to view Sustainability Dashboard
                </div>
            )}
            {(selectedProjects?.length === 0) && (
                selectedFacilities?.length === 0 ? (
                    <div>
                        Select a facility to view the available projects
                    </div>) : (
                    <div>
                        Select a project from the dropdown to view Sustainability Dashboard
                    </div>
                )
            )}
            {(selectedDashboards?.length === 0) && (
                <div>
                    Select a dashboard from the dropdown to view Sustainability Dashboard
                </div>
            )}

            {(selectedFacilities?.length > 0 && selectedDashboards?.length > 0 && selectedProjects?.length > 0 && showDashboard) &&
                <SupersetDashboard
                    facility_ids={selectedFacilities}
                    dashboardList={selectedDashboards}
                    projectList={selectedProjects}
                    client_id={client_id}
                    dashboardType='ESG'
                    dashboardHeight="460px"
                    showDashboard={showDashboard}
                    loadDashboard={loadDashboard}
                />
            }
        </div>
    )
}