import Grid from '@mui/material/Grid';
import { TextField, Tooltip, IconButton, FormControl, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { createNewFacility, setStoreData } from '../../Redux/actions';
import { getFacilityById } from '../../Redux/actions';
import { updateFacility, getClientLocations, uploadMedia, deleteClientMedia, getCurrentUserRole } from '../../Redux/actions';
import ConfirmDialog from '../Common/ConfirmDialog'
import AlertDialog from '../Common/AlertDialog';
import { CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import { makeid } from '../../utils'
import { Delete } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import { DeleteIcon } from '../Common/TableUI';
import { Can } from '../../Context/Can';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export default function ManageFacilities() {

    const location = useLocation();
    const locationState = location?.state;

    let navigate = useNavigate()
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }

    let initialForm = {
        facilityName: "",
        facilityShortName: "",
        address: "",
        map_url: "",
        segment: "",
        location: ""
    }
    const state = useSelector(st => st)
    // const { role: { data: { data: userRole } } } = state

    const [form, setForm] = useState(initialForm);
    const dispatch = useDispatch();
    let { id: id, client_id: client_id } = useParams()
    const [uploadedImage, setUploadedImage] = useState();
    const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    const error = {};
    const [errors, setErrors] = useState(error);
    const [locationData, setLocationData] = useState(null)
    const [facilityAction, setFacilityAction] = useState("Create New Facility")
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [facilityLocation, setFacilityLocation] = useState([])
    const [userRole, setUserRole] = useState([])

    const [viewPermission, setViewPermission] = useState(false);
    const [userAccess, setUserAccess] = useState([])

    const [itemNotFound, setItemNotFound] = useState(false);

    const handleChange = (e, fieldName) => {
        const { value } = e.target;
        const data = (prevState) => ({
            ...prevState,
            [fieldName]: value
        })
        const err = Object.assign({}, errors);
        if (err[fieldName]) {
            err[fieldName] = null;
        }
        setErrors(err);
        setForm(data);
        if(disableButton){
            setDisableButton(false)
        }
    };

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))

    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
        locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/facilities`);
    }

    const validateData = () => {
        const err = { ...errors };

        let hasErrors = false;
        // if (!form.facilityName || form.facilityName.trim() === '') {
        //     hasErrors = true;
        //     err.facilityName = 'This field is mandatory';
        // }

        const trimmedfacilityShortName = form?.facilityShortName?.trim();
        if (trimmedfacilityShortName === '') {
            hasErrors = true;
            err.facilityShortName = 'This field is mandatory';
        } 
        if (trimmedfacilityShortName?.length > 32) {
            hasErrors = true;
            err.facilityShortName = 'Maximum 32 characters allowed';
        }

        if (!form.address || form.address.trim() === '') {
            hasErrors = true;
            err.address = 'This field is mandatory';
        }

        if (!form.map_url) {
            hasErrors = true;
            err.map_url = 'This field is mandatory';
        } else if (!/^https:\/\/.*maps/i.test(form.map_url)) {
            hasErrors = true;
            err.map_url = 'Enter a valid Map URL';
        }

        if (!form.segment) {
            hasErrors = true;
            err.segment = 'This field is mandatory';
        }

        // if (!form.location) {
        //     hasErrors = true;
        //     err.location = 'This field is mandatory';
        // }

        if (selectedFile?.length <= 0) {
            hasErrors = true;
            err.media = 'Please upload an image'
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        setDisableButton(true)
        const checkForm = validateData();
        if (checkForm) {
            const data = {
                facilityName: form.facilityName,
                short_name: form.facilityShortName,
                address: form.address,
                map_url: form.map_url,
                segment: form.segment,
                client_id,
                location: form.location,
                entity_id: id,
                content_type: 'facility'
            }

            const formData = new FormData();
            formData.append('data', JSON.stringify(data))
            if (deletedItem?.length > 0) {
                dispatch(deleteClientMedia(client_id, id, deletedItem)).then(res => { })
            }
            if (uploadedFile?.length > 0) {
                uploadedFile.forEach((file) => {
                    formData.append('imageFile', file);
                    formData.append('type', 'image');
                    formData.append('size', file.size);

                });
            }
            if (id) {
                dispatch(updateFacility(formData, id)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                })
            } else {
                dispatch(createNewFacility(formData)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            }

        }

    };

    useEffect(() => {
        dispatch(getClientLocations(client_id)).then((res) => {
            if (res && res.status === 200) {
                setLocationData(res.data.data)
            }
        });
    }, [])

    useEffect(() => {

        if (id) {
            setFacilityAction("Edit Facility")
            dispatch(getFacilityById(client_id, id)).then((resp) => {
                const response = resp?.data?.facilityData
                if (!response) {
                    setItemNotFound(true);
                    return;
                }

                const { address, facility_name, map_url, segment, location, image_path, media, short_name } = response
                setSelectedFile([])
                media.forEach((mediaItem, idx) => {
                    const fileData = {
                        id: mediaItem.id,
                        fileUrls: mediaItem.path,
                        type: mediaItem.type
                    }
                    setSelectedFile((prevFiles) => [...prevFiles, fileData]);
                })
                const locationName = location.id
                const existData = {
                    facilityName: facility_name,
                    facilityShortName: short_name,
                    address: address,
                    map_url: map_url,
                    segment: segment,
                    location: locationName,

                }
                setForm(existData)
                setSelectedImage(image_path);
            })
        }
        dispatch(getCurrentUserRole()).then((userResp) => {
            setUserRole(userResp?.data?.data)
        })

    }, []);
    useEffect(() => {
        const isClientAdminUser = userRole?.find(item => 
            item?.permission?.some(val => val?.type === 'client' && (val?.permissions.edit || val?.permissions.view) && val.client_id === client_id)
        );
        const isGreenUser = userRole?.find(item => {
            if(item.role === "admin" && item.status !== 'Inactive') {
                return item
            }
        })
        for(const roles of userRole) {
            if(isGreenUser || isClientAdminUser) {
                setFacilityLocation(locationData)
            } else if(roles.permission) {
                let matchingLocation = []
                for(const permission of roles.permission) {
                    if(!permission.role) {
                        const location = locationData?.find(item => {
                            const isLocationMatch = (`loc-${item.id}` === permission.parentId || item.id === permission.itemId)
                            const isFacilityPermission = permission.name === "Facility" || (`loc-${item.id}` === permission.parentId && (permission.permissions.edit || permission.permissions.delete || permission.permissions.view) && id === permission.itemId );
                            const isEditOrViewPermission = (permission.itemId === id && permission.permissions.edit) || (permission.type === 'location' && (permission.permissions.edit || permission.permissions.delete || permission.permissions.view));                       
                            return (isLocationMatch && isFacilityPermission) || (isLocationMatch && isEditOrViewPermission);
                        });
                        
                        if (location && !matchingLocation.some(item => item && item.id === location.id)) {
                            matchingLocation.push(location);
                        }
                    }
                }
                if(matchingLocation?.length > 0) {
                    setFacilityLocation(matchingLocation)
                }
            }
        }
    }, [locationData, userRole])

    useEffect(() => {
        if (userRole?.length > 0) {
            let arr = []
            for (const item of userRole) {
                if (item.role === "admin") {
                    item.adminAccess = {
                        edit: true,
                        delete: true,
                        id: '',
                        role: item.role
                    };
                } else if (item.permission && Array.isArray(item.permission) && item.permission.length > 0) {
                    item.adminAccess = {};
                    for (const permission of item.permission) {
                        if (permission.permissions.edit) {
                            item.adminAccess.edit = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                        if (permission.permissions.delete) {
                            item.adminAccess.delete = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                        if (permission.permissions.view) {
                            item.adminAccess.view = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                    }
                }
                arr.push(item)
            }
            if (arr?.length > 0) {
                setUserAccess(arr)
            }
        }
    }, [userRole])

    useEffect(() => {
        let canEdit = false;
        let canDelete = false;
        let canView = false;
        let admin = false;
        if (userAccess?.length > 0) {
            for (let i = 0; i < userAccess.length; i++) {
                const uItem = userAccess[i];
                if (uItem && uItem.permission) {
                    for (let j = 0; j < uItem.permission.length; j++) {
                        const pItem = uItem.permission[j];
                        if (pItem.itemId === id || pItem.itemId == form.location) {
                            if (pItem.permissions.edit) {
                                canEdit = true
                            }
                            if (pItem.permissions.view) {
                                canView = true;
                            }
        
                            if (pItem.permissions.delete) {
                                canDelete = true
                            }
                        }
                        if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.edit) {
                            canEdit = true;
                            canDelete = true;
                        }
                        if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.view) {
                            canView = true
                        }
                        
                    }
                }
            }

            userAccess.find(item => {
                if (item.role === 'admin') {
                    canEdit = true;
                    canDelete = true;
                    canView = false;
                    admin = true;
                }
            })
            if(canView && !canEdit && !admin) {
                setViewPermission(true);
            }
        }
        
    }, [userAccess, form])

    
    const handleFileChange = (event) => {
        if (event.target.files.length) {
            const err = Object.assign({}, errors);
            if (err['media']) {
                err['media'] = null;
            }
            setErrors(err);
            const files = Array.from(event.target.files);
            files[0].id = selectedFile?.length;
            const uploadedFiles = [...uploadedFile, ...files];
            setUploadedFile(uploadedFiles);
            const fileUrls = files.map((file) => URL.createObjectURL(file));
            const fileData = {
                id: selectedFile?.length,
                fileUrls,
                type: 'image'
            }
            setSelectedFile(prevFiles => [...prevFiles, fileData]);
            event.target.value = '';
        }
        if(disableButton){
            setDisableButton(false)
        }
        // setShowConfirmUploadDialog(true)

    }

    const handleConfirmUpload = () => {
        const formData = new FormData();
        if (uploadedImage) {
            formData.append('imageFile', uploadedImage);
            formData.append('type', 'image');
            formData.append('size', uploadedImage?.size);
        }
        setShowConfirmUploadDialog(false)
    }

    const handleDeleteMedia = (imageId) => {
        const deleteMedia = [...selectedFile];
        const itemToDelete = deleteMedia.findIndex(item => item.id == imageId);
        deleteMedia.splice(itemToDelete, 1)
        const deleteUploadedFile = [...uploadedFile];
        const updatedFiles = deleteUploadedFile.filter((item) => item.id !== imageId);
        setUploadedFile(updatedFiles)
        setSelectedFile(deleteMedia)
        setDeletedItem((prevItems) => [...prevItems, imageId]);
    }

    if (id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>

    }
    return (
        <Paper elevation={1} style={{ margin: '16px 0px' }}>
            <Grid container className='w3-padding-small'>
                <Grid container className='w3-padding'>
                    <Grid item md={12} style={{ display: 'flex'}}>
                        <IconButton
                            style={{ marginBottom: '8px'}}
                            onClick={() => locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/facilities`)}
                            color="inherit">
                            <ArrowBackIosIcon fontSize='small'/>
                        </IconButton>
                        <h6 style={{ paddingBottom: '8px' }}>{facilityAction}</h6>
                    </Grid>
                    <Grid container item md={12}>
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <TextField
                                fullWidth
                                name="facilityName"
                                disabled
                                value={form.facilityName}
                                label="Name"
                                required
                                onChange={(e) => handleChange(e, 'facilityName')} />
                        </Grid>
                        {errors.facilityName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.facilityName}</div>}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <TextField
                                fullWidth
                                required
                                value={form.facilityShortName}
                                label="Facility Short Name"
                                name="facilityShortName"
                                onChange={(e) => handleChange(e, 'facilityShortName')} 
                                />
                        </Grid>
                        {errors.facilityShortName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.facilityShortName}</div>}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                name="address"
                                label="Address"
                                required
                                value={form.address}
                                onChange={(e) => handleChange(e, 'address')} />
                        </Grid>
                        {errors.address && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.address}</div>}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <TextField
                                fullWidth
                                name="map_url"
                                label="Map Url"
                                required
                                value={form.map_url}
                                onChange={(e) => handleChange(e, 'map_url')} />
                        </Grid>
                        {errors.map_url && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.map_url}</div>}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel disabled id="demo-multiple-name-label">Location *</InputLabel>
                                <Select
                                    fullWidth
                                    value={form.location}
                                    name="location"
                                    label="Location"
                                    disabled
                                    required
                                    onChange={(e) => handleChange(e, 'location')}
                                >
                                    {facilityLocation?.map(item => (
                                        <MenuItem key={item?.id} value={item?.id}>{item?.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {errors.location && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.location}</div>}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-multiple-name-label">Segment *</InputLabel>
                                <Select
                                    fullWidth
                                    value={form.segment}
                                    name="segment"
                                    label="Segment"
                                    required
                                    onChange={(e) => handleChange(e, 'segment')}
                                >
                                    <MenuItem value={'Hotels'}>Hotels</MenuItem>
                                    <MenuItem value={'Hospitals'}>Hospitals</MenuItem>
                                    <MenuItem value={'Resorts'}>Resorts</MenuItem>
                                    <MenuItem value={'Residential'}>Residential</MenuItem>
                                    <MenuItem value={'IT Parks'}>IT Parks</MenuItem>
                                    <MenuItem value={'Industries'}>Industries</MenuItem>
                                    <MenuItem value={'Malls'}>Malls</MenuItem>
                                    <MenuItem value={'Government'}>Government</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {errors.segment && <div style={{ marginTop: '-12px' }} className='error-text'>{errors.segment}</div>}
                        <Grid container item md={12} className='w3-margin-bottom'>
                            <label htmlFor="logo-file">
                                <input
                                    style={{ display: 'none' }}
                                    required
                                    id="logo-file"
                                    name="logoFile"
                                    type="file"
                                    accept="image/jpeg, image/png, image/gif"
                                    onChange={handleFileChange}
                                />

                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <Button
                                            className="w3-margin-top"
                                            color="primary"
                                            variant="outlined"
                                            size="medium"
                                            component="span">
                                            Upload Images
                                        </Button>
                                    </div>
                                </div>
                            </label>
                        </Grid>
                        {errors.media && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.media}</div>}
                        <Grid container item md={12} >
                            {selectedFile && selectedFile?.map((mediaItem, idx) => {
                                if (!mediaItem?.fileUrls) {
                                    return false;
                                }
                                const url = new URL(mediaItem.fileUrls?.length === 1 ? mediaItem?.fileUrls[0] : mediaItem?.fileUrls);
                                const srcUrl = url.href;
                                return (
                                    <Grid item md={4} style={{ position: 'relative'}}> 
                                        <Tooltip title="Delete" style={{ position: 'absolute', top: '8px', right: '6px' }}>
                                            <IconButton className='tm-runcase-drawer-delete-icon' aria-label="delete" onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteMedia(mediaItem.id);
                                            }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                        <img style={{ padding: '5px' }} width="100%" height="auto" src={srcUrl} alt={`uploadedImage-${idx}`} />
                                    </Grid>
                                );
                            })}
                        </Grid>

                        <Grid item md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Button
                                variant="outlined"
                                onClick={() => locationState?.status ? navigate(-1) : navigate(`/client/${client_id}/settings/facilities`)}

                            >
                                Cancel</Button>
                           {!viewPermission && <Can do="manage" on="EditFacility">
                            <Button
                                disabled={disableButton}
                                variant="contained"
                                onClick={handleSubmit}
                                style={{ marginLeft: '10px' }}
                            >
                                Submit</Button>
                            </Can>}
                        </Grid>
                    </Grid>
                </Grid>
                {alertMessage ?
                    (
                        <AlertDialog
                            handleClose={handleCloseDialog}
                            message={alertMessage}
                            type={msgType}
                            handleDialogClose={() => setAlertMessage(null)}
                        />
                    )
                    : null
                }
                {showConfirmUploadDialog
                    ? (
                        <ConfirmDialog
                            handleCancel={() => {
                                setShowConfirmUploadDialog(false)
                            }}
                            handleOk={handleConfirmUpload}
                            message={'Are you sure to Upload Image?'}
                        />
                    ) : null}
            </Grid>
        </Paper>
    )
}
