import React from 'react';
import { red, green } from '@mui/material/colors';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const AlertDialog = (props) => {
    const { title, message, type, handleClose, handleDialogClose } = props;
    return (
        <Dialog
            open={true}
        >
            <div style={{minWidth: '300px',}}>
                <DialogTitle id="alert-dialog-title">{title || ''}</DialogTitle>
                <DialogContent>
                    {type === 'fail'
                        ? <ErrorIcon fontSize="large"
                            style={{ color: red[500], marginLeft: '45%' }}
                        />
                        : <CheckCircleOutlineIcon fontSize="large"
                            style={{ color: green[500], marginLeft: '45%' }}
                        />
                    }
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {type === 'fail'
                        ? <Button onClick={handleDialogClose} color="primary">
                            OK
                        </Button>
                        : <Button onClick={handleClose} color="primary">
                            OK
                        </Button>
                    }
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default AlertDialog;
