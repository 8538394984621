import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Autocomplete, Box, TextField } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFacilities,
  getAllMeterTags,
  getAllMeterStatus,
  getAllProject,
  getClientLocations,
  getAllAssignees,
  getAllFacilitiesForTickets,
  getAllTicketStatus,
  getAllFacilityStatus
} from "../../Redux/actions";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Chip from "@mui/material/Chip";
import { Grid, Typography } from "@mui/material";
import { filter } from "lodash";
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Filter = (props) => {
  const dispatch = useDispatch();
  let { client_id: client_id } = useParams();

  const {
    segmentDataOption,
    showFilterDialog,
    setShowFilterDialog,
    sectionListOption,
    reportTypesOption,
    plantTypeOption,
    capacityOption,
    parameterGrpOption,
    alertOption,
    regionsOption,
    updateQueryParams,
    setIsSearchOrFilterApplied,
    queryParams,
    filterArea,
    clearFilters,
    handleTabSwitch,
    checkForUrlFacility,
    checkForMyTickets
  } = props;
  
  const allFilters = [
    "region",
    "location_id",
    "segment",
    "section",
    "type",
    "facility_id",
    "plantType",
    "capacity",
    "project_id",
    "meterTags",
    "meterGrp",
    "alert",
    "meterStatus",
    "assignee",
    "status",
    "scope",
    "facilityStatus",
  ];

  const existingParams = queryParams && Object.fromEntries([...queryParams]);
  const [filterOptions, setFilterOptions] = useState(existingParams);

  const [filterData, setFilterData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [dataFetched, setDataFetched] = useState(false);

  const [queryFilters, setQueryFilters] = useState(() => {
    const filteredParams = Object.keys(existingParams).reduce((acc, key) => {
      if (key !== "page" && key !== "tab" && key !== "search") {
        acc[key] = existingParams[key];
      }
      return acc;
    }, {});
    return filteredParams;
  });

  const filterDataOptions = useSelector((st) => ({
    projects: st.allProjects?.data?.allData?.map((item) => ({
      name: item.projname,
      id:item.pid
    })),
    locations: st.allLocations?.data?.data?.map((item) => ({
      id: item.id,
      name:item.name
    })),
    facilities: st.allFacilities?.data?.allData?.map((item) => ({
      name: item.facility_name,
      id: item.id
    })),
    meterTags: st.allMeterTags?.data?.meterTags?.map((item) => ({
      name: item.text,
      id: item.key
    })),
    meterStatus: st.allMeterStatus?.data?.meterStatus?.map((item) => ({
      name: item.name,
      id: item.id
    })),
    assignees: st.allAssignees?.data?.assignee?.map((item) => ({
      name: item.name,
      id: item.id
    })),
    status: st.allStatus?.data?.status?.map((item) => ({
      name: item.name,
      id: item.id
    })),
    ticketFacilities: st.allFacilitiesForTickets?.data?.allFacilities?.map((item) => ({
      name: item.facility_name,
      id: item.id
    })),
    scope: [
      {
        name: 'DESK',
        id: 'desk'
      },
      {
        name: 'PROJECTS',
        id: 'projects'
      }
    ],
    facilityStatus: st.allFacilityStatus?.data?.status?.map((item) => ({
      name: item.name,
      id: item.id
    })),
    // plantTypeOption: plantTypeOption
  }));
  // useEffect(() => {
    
  // }, [filterDataOptions]);

  
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOptions((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  //Get the location name from location_id
  const getLocationName = (locationId) => {
    const location = filterDataOptions?.locations?.find(
      (loc) => loc.id === locationId
    );
    return location ? location.name : locationId;
  };

  //Get the Facility name from facility_id
  const getFacilityName = (facilityId) => {
    if ( filterArea === 'Tickets') {
      const facility = filterDataOptions?.ticketFacilities?.find(
        (fac) => fac.id === facilityId
      );
      return facility ? facility.name : facilityId;
    } else {
      const facility = filterDataOptions?.facilities?.find(
        (fac) => fac.id === facilityId
      );
      return facility ? facility.name : facilityId;
    }
  };

  //Get the Project name from pid
  const getProjectName = (projectId) => {
    const project = filterDataOptions?.projects?.find(
      (proj) => String(proj.id) === String(projectId)
    );
    return project ? `${project.name} (PID:${project.id})` : projectId;
  };

  //Get the Tag name from meterTags
  const getTagName = (tagId) => {
    const tag = filterDataOptions?.meterTags?.find((tag) => tag.id === tagId);
    return tag ? tag.name : tagId;
  };

  //Get the ParameterGroup name from meterGrp
  const paraMeterGroupName = (meterGroup) => {
    const group = parameterGrpOption?.find(
      (meterGrp) => meterGrp.value === meterGroup
    );
    return group ? group.label : meterGroup;
  };

  //Converting the filter keys to actual values
  const transformFilters = () => {
    if (!filterOptions) {
      return;
    }
    const fltrOptions = {};
    for (const [key, value] of Object.entries(filterOptions)) {
      if (value) {
        fltrOptions[key] = value;
      }
    }

    if (Object.keys(fltrOptions).length > 0) {
      fltrOptions.page = 1;
    }

    const filters = Object.entries(fltrOptions)
    .filter(([key, value]) => allFilters.includes(key) && value)
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    
    if (filters.location_id) {
      filters.location_id = getLocationName(filters.location_id);
    }
    if (filters.facility_id) {
      filters.facility_id = getFacilityName(filters.facility_id);
    }
    if (filters.project_id) {
      filters.project_id = getProjectName(filters.project_id);
    }
    if (filters.meterTags) {
      filters.meterTags = getTagName(filters.meterTags);
    }
    if (filters.meterGrp) {
      filters.meterGrp = paraMeterGroupName(filters.meterGrp);
    }

    setAppliedFilters(filters);

    if (!filterArea === "Facility" || !filterArea === "Reports") {
      setIsSearchOrFilterApplied(true);
    }
  };

  const handleFilterApply = () => {

    if (filterArea === "Tickets") {
      handleTabSwitch();
    }

    transformFilters();

    const fltrOptions = {};
    for (const [key, value] of Object.entries(filterOptions)) {
      if (value) {
        fltrOptions[key] = value;
      }
    }

    if (Object.keys(fltrOptions).length > 0) {
      fltrOptions.page = 1;
    }

    updateQueryParams(fltrOptions);

    setShowFilterDialog(false);
  };

  const clearAllFilter = () => {
    const fOption = { ...filterOptions };
    if (fOption.hasOwnProperty("search")) {
      delete fOption.search;
    }
    if (fOption.hasOwnProperty("tab")) {
      delete fOption.tab;
    }
    Object.keys(fOption).forEach((key) => {
      fOption[key] = "";
    });
    updateQueryParams(fOption);
    setFilterOptions(fOption);
    setShowFilterDialog(false);
    setAppliedFilters("");
  };

  const clearChipFilter = (filterKey) => {
    const updatedFilterOptions = { ...filterOptions };

    if (updatedFilterOptions.hasOwnProperty("search")) {
      delete updatedFilterOptions.search;
    }
    if (updatedFilterOptions[filterKey]) {
      updatedFilterOptions[filterKey] = "";
    }


    setFilterOptions(updatedFilterOptions);
    if (appliedFilters[filterKey]) {
      appliedFilters[filterKey] = null;
    }
    setAppliedFilters(appliedFilters);
    updateQueryParams(updatedFilterOptions);

    // Update the applied filters
    // transformFilters();
  };

  useEffect(() => {
    if (filterData?.length === 0) return;
  
    const fetchedTypes = [];
    const dispatchPromises = [];
  
    const dispatchMap = {
      locations: () => dispatch(getClientLocations(client_id)),
      facilities: () => dispatch(getAllFacilities(client_id)),
      ticketFacilities: () => dispatch(getAllFacilitiesForTickets()),
      projects: () => dispatch(getAllProject(client_id)),
      meterTags: () => dispatch(getAllMeterTags()),
      meterStatus: () => dispatch(getAllMeterStatus()),
      assignees: () => dispatch(getAllAssignees()),
      status: () => dispatch(getAllTicketStatus()),
      facilityStatus: () => dispatch(getAllFacilityStatus())
    };
  
    filterData.forEach(item => {
      const dispatchAction = dispatchMap[item.type];
      if (dispatchAction) {
        dispatchPromises.push(
          dispatchAction().then(() => {
            fetchedTypes.push(item.type);
          })
        );
      }
    });
  
    Promise.all(dispatchPromises).then(() => {
      const allTypesFetched = filterData.every(item => fetchedTypes.includes(item.type));
      if (allTypesFetched) {
        setDataFetched(true);
      }
    });
  }, [filterData]);
  

  useEffect(()=>{
    if( (filterDataOptions && dataFetched) || (filterArea === "Reports")  ){
      transformFilters()
    }
  },[queryFilters, dataFetched])

  useEffect(() => {
    if (clearFilters) {
      // Reset appliedFilters to an empty string when tabSwitch is true
      setAppliedFilters("");
    }
  }, [clearFilters]);

  useEffect(() => {
    const fData = [];
    if (
      filterArea === "Facilities" ||
      filterArea == "Projects" ||
      filterArea == "Meters"
    ) {
      fData.push({
        type: "locations",
        label: "Location",
        name: "location_id",
        data: [],
      });

      // fetchClientLocations()
    }
    if (filterArea === "Projects" || filterArea == "Meters") {
      fData.push({
        type: "facilities",
        label: "Facility",
        name: "facility_id",
        data: [],
      });
    }
    if (filterArea === "Meters") {
      fData.push({
        type: "projects",
        label: "Projects",
        name: "project_id",
        data: [],
      });

      fData.push({
        type: "meterTags",
        label: "Meter Tags",
        name: "meterTags",
        data: [],
      });

      fData.push({
        type: "meterStatus",
        label: "Meter Status",
        name: "meterStatus",
        data: [],
      });
    }
    if (filterArea === "Facilities") {
      fData.push({
        type: "facilityStatus",
        label: "Status",
        name: "facilityStatus",
        data: [],
      });
    }
    if (filterArea == "Tickets") {
      if ( !checkForMyTickets ) {
        fData.push({
          type: "assignees",
          label: "Assignee",
          name: "assignee",
          data: [],
        });
      }

      fData.push({
        type: "status",
        label: "Status",
        name: "status",
        data: [],
      });

      

      if ( !checkForUrlFacility ) {
        fData.push({
          type: "ticketFacilities",
          label: "Facility",
          name: "facility_id",
          data: [],
        });
        // fData.push({
        //   type: "scope",
        //   label: "Scope",
        //   name: "scope",
        //   data: [],
        // });
      }
    }

    setFilterData(fData);
  }, [checkForMyTickets]);

  useEffect(() => {
    if (queryParams) {
      if (filterOptions && Object.keys(filterOptions).length <= 0) {
        clearAllFilter();
        return;
      }
      const filterOptionsFromParams = Object.fromEntries([...queryParams]);
      setFilterOptions(filterOptionsFromParams);
    }
  }, [queryParams]);

  //   useEffect(() => {
  //     if (
  //       queryParams &&
  //       (clientFacilityData.length > 0 ||
  //         locationData.length > 0 ||
  //         projectData.length > 0 ||
  //         meterTags.length > 0)
  //     ) {
  //       const filterOptionsFromParams = Object.fromEntries([...queryParams]);
  //       setFilterOptions(filterOptionsFromParams);
  //       handleFilterApply();
  //     }
  //   }, [clientFacilityData, locationData, projectData, meterTags]);



  // useEffect(() => {
  //   if(queryParams.get('meterGrp')){
  //     handleFilterApply();
  //   }
  // },[parameterGrpOption?.length > 0])


  return (
    <div>
      <div>
        {appliedFilters && Object.values(appliedFilters).some(value => value !== null) && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            direction="row"
            paddingBottom="8px"
          >
            <Grid container item md={12}>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 600, fontSize: "14px" }}
              >
                Filters:
              </Typography>
            </Grid>
            <Grid container item md={12}>
              {Object.entries(appliedFilters).map(([key, value], index) => {
                if (!value) {
                  return null;
                }
                return (
                  <Chip
                    key={index}
                    color="primary"
                    size="small"
                    label={`${value}`}
                    onDelete={() => clearChipFilter(key)}
                    variant="outlined"
                    style={{ margin: "2px 2px", padding: "4px" }}
                  />
                )
                })}
            </Grid>
          </Grid>
        )}
      </div>
      <Drawer
        anchor="right"
        open={showFilterDialog}
        onClose={() => setShowFilterDialog(false)}
        PaperProps={{
          style: {
            width: 300,
          },
        }}
      >
        <Box p={2} style={{ padding: "0px" }}>
        <DialogTitle style={{ padding: "20px 16px" }}>
          <Tooltip title="Go Back">
            <IconButton 
              style={{ paddingLeft: 0 }}
              onClick={() => setShowFilterDialog(false)}
              color="inherit">
              <ArrowBackIcon fontSize='small' />
            </IconButton>
          </Tooltip>
              Filter {filterArea}
          </DialogTitle>
          <DialogContent style={{ paddingLeft: "16px", paddingRight: "16px" }}>
            {filterData.map((item) => {
              return (
                <>
                  <InputLabel htmlFor={item.type} style={{ marginTop: "15px" }}>
                    {item.label}
                  </InputLabel>
                  {(item.type === 'ticketFacilities' || item.type === 'projects') ? (
                    <Autocomplete
                      options={filterDataOptions[item.type] || []}
                      value={filterDataOptions[item.type]?.find(option => option.id == (item.type === 'ticketFacilities' ? filterOptions?.facility_id : filterOptions?.project_id)) || null}
                      onChange={(event, newValue) => { 
                        handleFilterChange({ target: { name: item?.name, value: newValue?.id } });
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                      getOptionLabel={(option) => (item.type === 'ticketFacilities') ? option.name : `${option.name} (PID:${option.id})`}
                      disableClearable
                    />
                  ) : (
                    <Select
                      name={item.name}
                      value={filterOptions[item.name]}
                      onChange={handleFilterChange}
                      fullWidth
                    >
                      {filterDataOptions[item.type]?.map((fItem) => (
                        <MenuItem key={`${fItem.type}-${fItem.id}`} value={fItem.id}>
                          {fItem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </>
              );
            })}

            {(segmentDataOption?.length > 0 || filterArea === "Facilities") && (
              <>
                <InputLabel htmlFor="segment" style={{ marginTop: "15px" }}>
                  Segment
                </InputLabel>
                <Select
                  name="segment"
                  value={filterOptions?.segment}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {segmentDataOption?.map((item, idx) => (
                    <MenuItem key={`seg-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {(reportTypesOption?.length > 0 || filterArea === "Reports") && (
              <>
                <InputLabel htmlFor="type" style={{ marginTop: "15px" }}>
                  Type
                </InputLabel>
                <Select
                  name="type"
                  value={filterOptions?.type}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {reportTypesOption?.map((item, idx) => (
                    <MenuItem key={`sec-report-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {(sectionListOption?.length > 0 || filterArea === "Reports") && (
              <>
                <InputLabel htmlFor="section" style={{ marginTop: "15px" }}>
                  Section
                </InputLabel>
                <Select
                  name="section"
                  value={filterOptions?.section}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {sectionListOption?.map((item, idx) => (
                    <MenuItem key={`section-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {(plantTypeOption?.length > 0 || filterArea === "Projects") && (
              <>
                <InputLabel htmlFor="plantType" style={{ marginTop: "15px" }}>
                  Plant Type
                </InputLabel>
                <Select
                  name="plantType"
                  value={filterOptions?.plantType}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {plantTypeOption?.map((item, idx) => (
                    <MenuItem key={`seg-plant-type-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {(capacityOption?.length > 0 || filterArea === "Projects") && (
              <>
                <InputLabel htmlFor="capacity" style={{ marginTop: "15px" }}>
                  Capacity
                </InputLabel>
                <Select
                  name="capacity"
                  value={filterOptions?.capacity}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {capacityOption?.map((item, idx) => (
                    <MenuItem key={`seg-capacity-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {(parameterGrpOption?.length > 0 || filterArea === "Meters") && (
              <>
                <InputLabel htmlFor="meterGrp" style={{ marginTop: "15px" }}>
                  Parameter Group
                </InputLabel>
                <Select
                  name="meterGrp"
                  value={filterOptions?.meterGrp}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {parameterGrpOption?.map((item, idx) => (
                    <MenuItem
                      key={`seg-paramert-grp-${idx}`}
                      value={item?.value}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {(alertOption?.length > 0 || filterArea === "Meters") && (
              <>
                <InputLabel htmlFor="alert" style={{ marginTop: "15px" }}>
                  Alert Priority
                </InputLabel>
                <Select
                  name="alert"
                  value={filterOptions?.alert}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {alertOption?.map((item, idx) => (
                    <MenuItem key={`seg-alert-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {(regionsOption?.length > 0 || filterArea === "Clients") && (
              <>
                <InputLabel htmlFor="region">Region</InputLabel>
                <Select
                  name="region"
                  value={filterOptions?.region}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {regionsOption?.map((region) => (
                    <MenuItem key={`region_${region}`} value={region}>
                      {region}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {/* {(assigneeOptions?.length > 0 || filterArea === "Tickets") && (
              <>
                <InputLabel htmlFor="capacity" style={{ marginTop: "15px" }}>
                  Assignee
                </InputLabel>
                <Select
                  name="assignee"
                  value={filterOptions?.assignee}
                  onChange={handleFilterChange}
                  fullWidth
                >
                  {assigneeOptions?.map((item, idx) => (
                    <MenuItem key={`seg-assignee-${idx}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )} */}
          </DialogContent>
          <Grid container item md={12} direction="column" paddingX={1}>
            <DialogActions>
              <Grid item container md={6} justifyContent="center">
                <Button onClick={clearAllFilter} variant="outlined">
                  Clear Filter
                </Button>
              </Grid>
              <Grid item container md={6} justifyContent="center">
                <Button onClick={handleFilterApply} variant="contained">
                  Apply Filter
                </Button>
              </Grid>
            </DialogActions>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

export default Filter;
