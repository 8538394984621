import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAsyncError } from "react-router-dom";
import { getCurrentUserRole } from '../../Redux/actions';

import { useDispatch } from 'react-redux';

export const SystemLog = () => {
    const [optionFetch,setOptionFetch]  = useState(Date.now());
    const [optionList, setOptionList] = useState([]);
    const [logName, setLogName] = useState(null)
    const [content, setContent] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCurrentUserRole()).then((userResp) => {
          })
    }, [])

    useEffect(() => {
        axios.get('/api/v1/client/get-log-list').then(result => {
            setOptionList(result.data); 
        })
    }, [optionFetch]);
    const triggerSync = () => {

        setContent('Triggering new Sync')
        axios.get('/api/v1/client/add-zoho-clients').then(result=>{
            setContent(`Select Log File ${result.data.name} (You can sync only once in 10 mins due to ZOHO rate limits)`);
            setOptionFetch(Date.now());
            // setLogName(null)
        });
    }

    useEffect(() => {
        if (!logName) {
            return;
        }
        axios.get('/api/v1/client/get-log-content', {
            params: {
                fname: logName
            }
        }).then(resp => {
            setContent(resp.data);
        })
    }, [logName,optionFetch])


    return <Grid container direction={"column"} spacing={2} padding={2}>
        <Grid item>
            <h3>System Log</h3>
        </Grid>
        <Grid item padding={2} justifyContent={'space-between'}>
            <Stack spacing={2} padding={2} direction={'row'}>
                <FormControl size="small">
                    <InputLabel id="log-select-label">Select Log</InputLabel>
                    <Select
                        labelId="log-select-label"
                        value={logName}
                        label="Select Log"
                        onChange={e => { setLogName(e.target.value) }}
                    >
                        {optionList.map(item => <MenuItem value={item}> {item}</MenuItem>)}
                    </Select>
                </FormControl> 
                <Button variant="outlined" filled onClick={triggerSync}>Trigger New Sync</Button> 
            </Stack>
        </Grid>
        <Grid item>
            <div style={{ whiteSpace: 'pre-wrap' }}>{content.split('\n').map(item=>{
                if (item.indexOf('ERROR') > -1){
                    return <div style={{color:'red',fontWeight:'bold'}}>{item}</div>
                }
                if (item.indexOf('INFO') > -1){
                    return <div style={{color:'green',fontWeight:'bold'}}>{item}</div>
                }
                if (item.indexOf('WARN') > -1){
                    return <div style={{color:'#d27539',fontWeight:'bold'}}>{item}</div>
                }
                
                return <div>
                    {`${item}\n`}
                </div>
            })}</div>
        </Grid>

    </Grid>
}

