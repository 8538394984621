
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';

import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import { Can } from '../../Context/Can';
import LiveView from '../Live';
import ReportView from '../AiDashboard/ReportView';
import ManageProjects from './ManageProjects';
import AssetsDetails from '../AssetsDetails';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SiteVisitReportList from './SiteVisitReportList';

export default function ProjectCommon() {
    const { client_id, tabId, id } = useParams();
    const pid = Number(id);
    const navigate = useNavigate();
    const [value, setValue] = useState('1');

    const projectQueryParams = localStorage.getItem("projectQueryParams");

    useEffect(() => {
        setValue(tabId)
      }, [tabId]);

    const handleTabSwitch = (event, newValue) => {
        setValue(newValue);
        navigate(`/client/${client_id}/projects/${newValue}/${pid}`)
      };    

      const tabNames = ['live', 'summary-table', 'report-view', 'edit', 'site-visit-report'];

    let found = false;
    for (const tabName of tabNames) {
        if (window.location.pathname.includes(tabName)) {
            found = true;
            break;
        }
    }

    if (!found) {
        navigate('/page-not-found');
    }

    return <>
        <div className='w3-padding-large'>
            <div >
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box id="digital-twin-tab-container" sx={{ borderBottom: 1, borderColor: 'divider',  display: 'flex', alignItems: 'center' }}>
                        <   IconButton 
                            onClick={() => projectQueryParams ? navigate(`/client/${client_id}/projects?${projectQueryParams?.toString()}`) : navigate(`/client/${client_id}/projects`)}
                            color="inherit">
                                <ArrowBackIosIcon fontSize='small'/>
                            </IconButton>
                            <TabList onChange={handleTabSwitch} aria-label="lab API tabs example">
                                <Tab label="Live View" value="live" />
                                <Tab label="Summary Table" value="summary-table" />
                                <Tab label="Reports" value="report-view" />
                                <Tab label="Site Visit" value="site-visit-report" />
                                <Tab label="Edit Project" value="edit" />
                                
                            </TabList>
                        </Box>
                        <TabPanel style={{ padding: '0px' }} value="live"><Can I="view" a="Live"><LiveView /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="summary-table"><Can I="view" a="Assets"><AssetsDetails /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="report-view"><Can I="view" a="ReportView"><ReportView /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="site-visit-report"><Can I="view" a="SiteVisitReport"><SiteVisitReportList /></Can></TabPanel>
                        <TabPanel style={{ padding: '0px' }} value="edit"><Can I="view" a="EditProject"><ManageProjects /></Can></TabPanel>
                        
                    </TabContext>
                </Box>
            </div>
        </div>
    </>
}